import styled from "@emotion/styled";
import { Box, Typography } from "@mui/material";
import CurrentSortIcon from "components/molecules/PopoverForSort/CurrentSortIcon";
import React from "react";
import ordinaryColors from "themes/ordinaryColors";

interface TitleWithFilterCounterProps {
  selectedLength?: number;
  title: string;
  paddingRight?: string;
  hasPlaceholder?: boolean;
  flexStart?: boolean;
  selectedSorting?: string;
}

export const StyledFilterCounterBox = styled(Box)({
  height: "24px",
  width: "24px",
  borderRadius: "50%",
  backgroundColor: ordinaryColors.functionalBlack,
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  marginLeft: "5px",
});

export const StyledFilterCounterContainer = styled(Box)(({ flexStart }: { flexStart?: boolean }) => ({
  minWidth: "100%",
  display: "flex",
  alignItems: "center",
  justifyContent: flexStart ? "flex-start" : "flex-end",
  gap: "1rem",
}));
const TitleWithFilterCounter: React.FC<TitleWithFilterCounterProps> = ({
  selectedLength,
  selectedSorting,
  title,
  paddingRight,
  hasPlaceholder,
  flexStart,
}) => {
  return (
    <StyledFilterCounterContainer flexStart={flexStart}>
      {!hasPlaceholder ? (
        <Typography sx={{ textOverflow: "hidden", paddingRight: paddingRight ?? "10px" }}>{title}</Typography>
      ) : null}
      {selectedSorting ? (
        <CurrentSortIcon value={selectedSorting} isUnitsOverviewSorting />
      ) : (
        <StyledFilterCounterBox>
          <Typography
            sx={{
              color: ordinaryColors.functionalWhite,
              fontSize: "18px",
              textOverflow: "hidden",
              whiteSpace: "nowrap",
            }}
          >
            {selectedLength}
          </Typography>
        </StyledFilterCounterBox>
      )}
    </StyledFilterCounterContainer>
  );
};

export default TitleWithFilterCounter;
