import { bold, center, LegalNoteLink, nextLine, nextParagraph } from "components/mlpaUiComponents/LegalNote/lib";

export default {
  databaseCardLabel: "Units & Certificates",
  databaseCardText: "Access the Public Register instantly to view publicly available unit certificates",
  days: "Days",
  faq: "FAQ",
  faqMessage: "Learn about topics that are likely to interest you.",
  headline: "ZEREZ - Central Register for Unit and Component Certificates",
  hours: "Hours",
  logInCardLabel: "Log In",
  logInCardText: "To access all features, simply log in with your username and password",
  minutes: "Minutes",
  otherDocuments: "Notes, templates and documents",
  otherDocumentsMessage: "Here you will find information, templates and documents for use with the register.",
  ourVision: "Our Vision",
  ourVisonMessage: "See who ZEREZ is primarily designed for and what the goals of this project are.",
  question: "What do you want to do?",
  registrationCardLabel: "Registration",
  registrationCardText: "Don`t have an account yet? Click here to create one.",
  seconds: "Seconds",
  countdownTo: "Countdown to the start of the mandatory use phase",
  linkToRegister: "Link to Register",
  linkToLogin: "Link to Login",
  linkToUnitsAndCertificates: "Link to Units and Certificates",
  linkToFaqs: "Link to FAQs",
  linkToOtherDocuments: "Link to Notes, Templates and Documents",
  textBlockOne: `
    Welcome to the Central Register for Unit and Component Certificates (ZEREZ) of FGW e. V.
    ${nextParagraph()}ZEREZ collects the unit and component certificates of all voltage levels in one place. These certificates on the electrotechnical properties of generating units and electrical components can be entered and retrieved here by authorized and interested parties.
    ${nextParagraph()}According to the ${LegalNoteLink({
    url: "https://www.gesetze-im-internet.de/nelev/BJNR165100017.html",
    ariaLabel: "Link to",
    label: "Electrotechnical Properties Verification Ordinance (NELEV)",
  })} of the Federal Ministry of Economics and Climate Protection (BMWK), market participants are obliged to use the central register from February 1, 2025 (see Section 7 (4) NELEV).
  `,
  textBlockTwo: ({
    certificateCount,
    unitCount,
  }: {
    certificateCount: number | undefined;
    unitCount: number | undefined;
  }) => `
    ${
      certificateCount &&
      `${center(
        bold(`There are currently ${certificateCount} certificates and ${unitCount} units on file in the register.`)
      )}`
    }
    ${nextLine()}FGW e. V. has been entrusted with the operation of ZEREZ as an entrusted entity since July 17, 2024. The legal basis for the entrustment is Section 49d (3) sentence 1 of the Energy Industry Act (EnWG).
    
    ${nextParagraph()}Certificates can be published and managed by manufacturers and certification bodies. A customised data interface enables network operators to process all available certificates.
     ${nextParagraph()}${center(
    `Please contact us if you have any questions or suggestions. An FAQ and further information are also available.`
  )}
    ${nextParagraph()}${center(
    bold(`A certificate you require is missing? - Please contact the manufacturer and let them know.`)
  )}
  `,
};
