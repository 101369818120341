export default {
  kpiTitle: "Anzahl registrierter Einheiten",
  modelName: "Typ / Modell Name",
  // name: "Einheit",
  // pluralName: "Einheiten",
  // ratedActivePower: "Bemessungswirkleistung",
  // ratedApparentPower: "Bemessungsscheinleistung",
  // ratedCurrent: "Bemessungsstrom",
  searchUnits: "Einheiten suchen",
  unitNumber: "ZEREZ ID der Einheit",
  unitTypeName: "Kategorie/Klasse/Betriebsmittel",
};
