export default {
  category: {
    INVERTER: "Inverter",
    STORAGE_INVERTER: "Storage Inverter",
    ASYNCHRONOUS_GENERATOR: "Asynchronous Generator",
    SYNCHRONOUS_GENERATOR: "Synchronous Generator",
    FUEL_CELL: "Fuel Cell",
    UNDERVOLTAGE_PROTECTION: "Undervoltage Protection",
    PAV_E_MONITORING: "PAV, E-Monitoring",
    VOLTAGE_REGULATOR: "Voltage Regulator",
    PROTECTIVE_DEVICE_INTERMEDIATE: "Protective Device",
    EZA_CONTROLLER: "PGS Controller",
    OTHER: "Other",
  },
  primaryEnergySource: {
    // BIOGAS: "Biogas",
    NOT_APPLICABLE: "Not Applicable",
    OTHER: "Other",
    OTHER_FUELS: "Gaseous and liquid fuels",
    PV: "PV",
    PV_WITH_STORAGE: "PV / Storage",
    STORAGE: "Storage",
    WATER: "Water",
    WIND: "Wind",
  },
  netFeedIn: {
    SINGLE_PHASE: "Single Phase",
    TWO_PHASE: "Two Phase",
    THREE_PHASE: "Three Phase",
    UNKNOWN: "Unknown",
  },
  sort: {
    ASC: "Ascending",
    DESC: "Descending",
    Default: "Default",
  },
  tenantRole: {
    MANUFACTURER: "Manufacturer",
    CERTIFICATION_AUTHORITY: "Certification Body",
    NETWORK_OPERATOR: "Network Operator",
    PLATFORM_OPERATOR: "Platform Operator",
    PLANNER: "Planner",
    TENANT_ADMIN: "Tenant Admin",
  },
  accessRequestStatus: {
    REQUESTED: "Pending Request",
    APPROVED: "Access Granted",
    REJECTED: "Access Denied",
  },
  approvalStatus: {
    PENDING: "Pending",
    APPROVED: "Approved",
    DENIED: "Declined",
    PENDING_INVITATION: "Pending Invite",
    WITHOUT_EA_APPROVAL: "Without EA Approval",
  },
  certificateValidityStatuses: {
    "Expired / Invalid": "Expired",
    "Withdrawn (replaced by)": "Withdrawn (replaced)",
    "Withdrawn (not replaced)": "Withdrawn (not replaced)",
    "Valid / Ongoing": "Valid",
    Void: "Invalid",
  },
  issueStatusName: {
    Open: "Open",
    Closed: "Closed",
    Resolved: "Resolved",
  },
  issueSubTypeNameForFilter: {
    Attachments: "Attachments",
    "Cover Sheet": "Cover Sheet",
    "Digital Attachment": "Digital Attachment",
    "Unit Parameters": "Unit Parameters",
    Validity: "Validity",
  },

  issueSubTypeName: {
    "7b60458e-c025-5dbe-8825-0ef45de4e5d5": "Attachment",
    "57b08c29-018e-5909-85bf-f4d11a7e8aa2": "Cover Sheet",
    "48442616-8f96-5d19-beb6-9700fd4be435": "Digital Attachment",
    "1a614968-01aa-585c-bcfc-0aad8ba2ee63": "Unit Parameters",
    "6ca69681-8ff3-55e4-afc7-b8f376f9ee22": "Validity",
  },
};
