import { atom, DefaultValue, selector, selectorFamily } from "recoil";
import translations from "translations";
import { certificateFileTypes } from "../config";
import { UploadCertificateFile } from "../types";

export const uploadCertificateFilesState = atom<UploadCertificateFile[]>({
  key: "UploadCertificateFilesState",
  default: [],
});
export const uploadCertificateFeedbackState = atom<
  keyof typeof translations.pages.certificateUpload.feedBack | undefined
>({
  key: "UploadCertificateFeedbackState",
  default: undefined,
});

export const uploadCertificateFilesSelectorFamily = selectorFamily<UploadCertificateFile | undefined, string>({
  key: "UploadCertificateFilesSelectorFamily",
  get:
    (fileTypeName) =>
    ({ get }) => {
      const files = get(uploadCertificateFilesState);
      return files.find((file) => file.certificateFileTypeId === getFileTypeIdForFileTypeName(fileTypeName));
    },
  set:
    (fileTypeName) =>
    ({ get, set }, newValue) => {
      if (newValue instanceof DefaultValue) {
        return;
      }
      const files = get(uploadCertificateFilesState);
      const fileTypeId = getFileTypeIdForFileTypeName(fileTypeName);
      const fileIndex = files.findIndex((file) => file.certificateFileTypeId === fileTypeId);
      if (newValue === undefined) {
        if (fileIndex >= 0) {
          set(uploadCertificateFilesState, [...files.slice(0, fileIndex), ...files.slice(fileIndex + 1)]);
        }
        return;
      }
      if (fileIndex >= 0) {
        set(uploadCertificateFilesState, [...files.slice(0, fileIndex), newValue, ...files.slice(fileIndex + 1)]);
      } else {
        set(uploadCertificateFilesState, [...files, newValue]);
      }
    },
});

export const uploadCertificateFilesMultiSelectorFamily = selectorFamily<UploadCertificateFile[], string>({
  key: "UploadCertificateFilesMultiSelectorFamily",
  get:
    (fileTypeName) =>
    ({ get }) => {
      const files = get(uploadCertificateFilesState);
      return files.filter((file) => file.certificateFileTypeId === getFileTypeIdForFileTypeName(fileTypeName));
    },
  set:
    (fileTypeName) =>
    ({ get, set }, newFiles) => {
      if (newFiles instanceof DefaultValue) {
        return;
      }
      const files = get(uploadCertificateFilesState);
      const otherTypeFiles = files.filter(
        (file) => file.certificateFileTypeId !== getFileTypeIdForFileTypeName(fileTypeName)
      );
      const cleanNewFiles = newFiles.filter(
        (file) => file.certificateFileTypeId === getFileTypeIdForFileTypeName(fileTypeName)
      );
      set(uploadCertificateFilesState, [...otherTypeFiles, ...cleanNewFiles]);
    },
});

export const uploadFiletypeListSelector = selector<string>({
  key: "UploadFiletypeListSelector",
  get: ({ get }) => {
    const files = get(uploadCertificateFilesState);
    return files.map((file) => getFileTypeNameForFileTypeId(file.certificateFileTypeId)).join(",");
  },
});
export const uploadFilesAggregatedSizeSelector = selector<number>({
  key: "uploadFilesAggregatedSizeSelector",
  get: ({ get }) => {
    const files = get(uploadCertificateFilesState);
    return files.reduce((acc, file) => acc + file.size, 0);
  },
});

export const getFileTypeIdForFileTypeName = (fileTypeName: string) =>
  certificateFileTypes[fileTypeName as keyof typeof certificateFileTypes];

export const getFileTypeNameForFileTypeId = (fileTypeId: string) =>
  Object.entries(certificateFileTypes).find(([, value]) => {
    return value === fileTypeId;
  })?.[0];
