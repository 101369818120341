import { Box } from "@mui/material";
import ModalWithButtons from "components/molecules/Modal/ModalWithButtons";
import translations from "translations";
import useGetTenantNameById from "utils/useGetTenantNameById";

interface TenantCanNotBeDeletedModalProps {
  tenantId: string | undefined;
  openCheckTenantRole: boolean;
  setOpenCheckTenantRole: (open: boolean) => void;
  afterUpdateUser: () => void;
}

const CheckTenantRole: React.FC<TenantCanNotBeDeletedModalProps> = ({
  tenantId,
  openCheckTenantRole,
  setOpenCheckTenantRole,
  afterUpdateUser,
}) => {
  const { getTenantById } = useGetTenantNameById({});
  return (
    <ModalWithButtons
      openModal={openCheckTenantRole}
      onClose={() => {
        setOpenCheckTenantRole(false);
      }}
      title={translations.pages.userManagement.form.label.checkTenantRole}
      addButtonTitle={translations.globals.button.yes}
      addButtonClick={() => afterUpdateUser()}
      cancelTitle={translations.globals.button.cancel}
      width="715px"
    >
      <Box
        dangerouslySetInnerHTML={{
          __html: translations.pages.userManagement.form.message.checkTenantRole({
            tenantName: tenantId ? getTenantById(tenantId) : undefined,
          }),
        }}
      />
    </ModalWithButtons>
  );
};

export default CheckTenantRole;
