import { atom } from "recoil";

export const newTenantIdstate = atom<string>({
  key: "newTenantIdstate",
  default: "",
});
export const openCheckTenantRoleState = atom<boolean>({
  key: "openCheckTenantRoleState",
  default: false,
});
export const userIdState = atom<string[] | string | undefined>({
  key: "userIdState",
  default: undefined,
});
export const editUserOpenModalState = atom<boolean>({
  key: "editUserOpenModalState",
  default: false,
});
export const deleteUserOpenModalState = atom<boolean>({
  key: "deleteUserOpenModalState",
  default: false,
});
export const tenantIdForDeleteTenantState = atom<string>({
  key: "tenantIdForDeleteTenantState",
  default: "",
});
