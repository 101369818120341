import { useDebounce } from "pages/CertificationManagement/CustomDataGridFilters/utils/useCustomFilterbar";
import { dummyIdPrefix } from "pages/UploadCertificate/config";
import {
  activeUploadCertificateUnitIndexAtom,
  unitFormValidationSingleSetSelector,
  uploadCertificateUnitListAtom,
  uploadCertificateUnitSharedValuesAtom,
} from "pages/UploadCertificate/state/uploadCertificateUnitState";
import { UploadCertificateUnit } from "pages/UploadCertificate/types";
import uniFormValidationRules from "pages/UploadCertificate/validationRules/uniFormValidationRules";
import { getCertificateCreateFieldSelector } from "raft/CertificateForm";
import { UnitInputCreate } from "raft/internal/schemaTypes";
import {
  getUnitCreateFormAtom,
  UnitCreateAdditionalFields,
  UnitCreateForm,
  UnitCreateOnUpdateDataMiddleWareProps,
  UnitCreateProps,
} from "raft/UnitForm";
import { useCallback, useEffect, useRef } from "react";
import { useRecoilCallback, useRecoilValue, useSetRecoilState } from "recoil";
import { v4 as uuidv4 } from "uuid";
import updatePowerRangesFieldBehaviour from "./updatePowerRangesFieldBehaviour";

type FormState = Omit<UnitInputCreate & UnitCreateAdditionalFields, "id"> & {
  id: string;
};

type FormStateRef = React.MutableRefObject<FormState | undefined>;
export const unitDefaultValues: Partial<UnitInputCreate> = {
  revision: 0,
};

const useConnectUnitForm = (formStateRef: FormStateRef) => {
  const createUnitAtom = getUnitCreateFormAtom({});
  const activeUnitIndex = useRecoilValue(activeUploadCertificateUnitIndexAtom);

  const loadFormValues = useRecoilCallback(({ set, snapshot }) => {
    return async (activeUnitIndex: number) => {
      const unit = await snapshot.getPromise(uploadCertificateUnitListAtom);
      const sharedValues = await snapshot.getPromise(uploadCertificateUnitSharedValuesAtom);
      const values = unit[activeUnitIndex];
      const id = values?.id ?? `${dummyIdPrefix}${uuidv4()}`;
      const injectedValues = {
        ...unitDefaultValues,
        ...values,
        ...sharedValues,
        id,
      } as UnitCreateProps["injectedValues"] & { id: string };
      set(createUnitAtom, injectedValues);
      formStateRef.current = injectedValues;
      return injectedValues;
    };
  }, []);

  const debouncedUnitIndex = useDebounce(activeUnitIndex, 1);
  useEffect(() => {
    loadFormValues(debouncedUnitIndex);
  }, [loadFormValues, debouncedUnitIndex]);
};

const useCreateOnUpdateDataMiddleWare = (formStateRef: FormStateRef) => {
  const setUploadCertificateUnitListAtom = useSetRecoilState(uploadCertificateUnitListAtom);
  const _uuitsModifiedByUserAtom = getCertificateCreateFieldSelector({})("__unitsModifiedByUser");
  const setUnitsMOdifiedByUser = useSetRecoilState(_uuitsModifiedByUserAtom);
  return useCallback(
    (onUpdateDataMiddleWareProps: UnitCreateOnUpdateDataMiddleWareProps) => {
      const { data } = onUpdateDataMiddleWareProps;
      if (data.id && formStateRef.current?.id !== data.id) {
        return formStateRef.current as UploadCertificateUnit;
      }
      setUnitsMOdifiedByUser((v) => (v ?? 0) + 1);
      setUploadCertificateUnitListAtom((old) => {
        const newUnitList = [...old];
        const nextUnitList = newUnitList.reduce<UploadCertificateUnit[]>((nextList, unit) => {
          if (unit.id === data.id) return nextList.concat(data as UploadCertificateUnit);
          return nextList.concat(unit);
        }, []);
        if (!nextUnitList.length) return [data as UploadCertificateUnit];
        return nextUnitList;
      });
      // logic for certificates with power ranges
      const dataWithUpdatedPowerRanges = updatePowerRangesFieldBehaviour(data);

      return {
        ...dataWithUpdatedPowerRanges,
        // isImported: false
      };
    },
    [setUploadCertificateUnitListAtom, formStateRef, setUnitsMOdifiedByUser]
  );
};

const ConnectedUnitForm: React.FC = () => {
  const formStateRef = useRef<undefined | FormState>(undefined);
  useConnectUnitForm(formStateRef);
  const onUpdateDataMiddleWare = useCreateOnUpdateDataMiddleWare(formStateRef);
  const setValidationCallback = useSetRecoilState(unitFormValidationSingleSetSelector);
  return (
    <UnitCreateForm
      onUpdateDataMiddleWare={onUpdateDataMiddleWare}
      validationRules={uniFormValidationRules}
      validateOnLoad={!!formStateRef.current?.formErrorCount}
      setValidationCallback={setValidationCallback}
    />
  );
};
export default ConnectedUnitForm;
