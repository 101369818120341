export default {
  label: "Fehlerberichte",
  description: "Beschreibung",
  certificateHolder: "Zertifikatsinhaber",
  status: "Status",
  errorType: "Fehlerart",
  reportedOn: "Gemeldet am",
  reportedBy: "Gemeldet von",
  resolvedOn: "Gelöst am",
  resolvedBy: "Gelöst von",
  openSince: "Offen seit (Tage)",
};
