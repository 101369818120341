export default {
  columns: {
    createdAt: "Created at",
    id: "ID",
    isDeleted: "is deleted",
    modifiedAt: "Modified at",
    tenantIdentifier: "Tenant ID",
    tenantName: "Tenant Name",
    tenantRole: "Tenant Role",
    tenantType: "Tenant Type",
  },
  tenantIfo: "Tenant information",
  kpiTitle: "Total manufacturers registered",
  name: "Tenant",
  phone: "Phone number",
  busnissAddress: "Business address",
  zipCode: "Zip code",
  country: "Country",
  city: "City",
  streetAndNumber: "Street and number",
  plural: "Tenants",
  amoutOfUsers: "Amount of users",
  amountOfUnits: "Amount of units",
  amoutOfCertificates: "Amount of certificates",
  backToTenants: "Back to tenants",
};
