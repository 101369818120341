import { TypedQuery, TypedVariables } from "components/templates/dataGridTable/serverSide/types";
import { EntityMappings } from "graphqlBase/__utils__/entityMappings";
import { useCallback, useEffect, useState } from "react";
import { atom, useRecoilState } from "recoil";
// eslint-disable-next-line import/no-extraneous-dependencies, import/no-unresolved
import { merge } from "ts-deepmerge";
import { useFilterBuilder } from "./useFilterBuilder";
import { useSortBuilder } from "./useSortBuilder";
export function useDebounce<T>(value: T, delay?: number): T {
  const [debouncedValue, setDebouncedValue] = useState<T>(value);

  useEffect(() => {
    const timer = setTimeout(() => {
      setDebouncedValue(value);
    }, delay ?? 100);

    return () => {
      clearTimeout(timer);
    };
  }, [value, delay]);

  return debouncedValue;
}

export const unitDataGridQueryState = atom<TypedVariables<"UnitOverview">>({
  key: "unitDataGridQueryState",
  default: {},
});

export function useCustomFilterbar<E extends keyof EntityMappings>(query: TypedQuery<E>) {
  const [variables, setVariables] = useState<TypedVariables<E>>();
  const [filterState, setFilterState] = useRecoilState<TypedVariables<"UnitOverview">>(unitDataGridQueryState);
  const { filters } = useFilterBuilder();
  const { sortings } = useSortBuilder();

  const debouncedValue = useDebounce<TypedVariables<E> | undefined>(variables, 100);
  useEffect(() => {
    setVariables(merge(filterState, { where: { and: [filters] }, order: sortings }) as TypedVariables<E>);
  }, [filters, filterState, setVariables, sortings]);

  useEffect(() => {
    if (debouncedValue) {
      query({
        variables: debouncedValue,
      });
    }
  }, [query, debouncedValue]);

  const mockedQuery: TypedQuery<E> = useCallback(
    (options) => {
      setFilterState({
        where: options?.variables?.where,
        skip: options?.variables?.skip,
        order: options?.variables?.order,
        take: options?.variables?.take,
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [query]
  );
  return mockedQuery;
}
