import { execGetTenantsForRoleQuery } from "graphqlBase/tenant/__generated__/getTenantsForRole";
import { parse } from "date-fns";
import { TenantRole } from "graphqlBase/types";
import { CertificateFieldMapping, CertificateFieldMappingGetter } from "pages/UploadCertificate/types";
import { getCertificateBridgeCertificateNormIssueDates, getNormList } from "./getNorms";
import { getsoftwareVersions } from "./getSoftWareVersions";

const certificateTypeGetter: CertificateFieldMappingGetter = ({ value }) => {
  switch (value) {
    case "Zertifikat":
    case "Certificate":
      return "1311341d-f702-51f3-ad44-e26ff3d677a1";
      break;
    case "Prototypenbestätigung":
    case "Prototype confirmation":
      return "a84b6197-3dcc-52f4-a325-6a44157e27d4";

    case "Herstellererklärung":
    case "Manufacturer declaration":
      return "a980cca4-ca1b-5954-9d95-d784a5008571";
      break;

      return undefined;
  }
};

const certificateValidityStatusGetter: CertificateFieldMappingGetter = ({ value }) => {
  switch (value) {
    case "Gültig / laufend":
    case "Valid/ongoing":
      return "c47a0b7c-701b-5544-92b0-6bcb48aac633";
      break;
    case "Zurückgezogen (ersetzt durch)":
    case "Withdrawn (replaced by)":
      return "38fa47c8-12a4-5e6f-b9d1-387d3c8222b8";

    case "Zurückgezogen (nicht ersetzt)":
    case "Withdrawn (not replaced)":
      return "64e89d04-9533-57f4-8f2d-41f340a29d8b";
      break;
    case "Abgelaufen / ungültig":
    case "Expired/invalid":
      return "63a507c8-7124-5eec-8510-02b74a3a8dca";
      break;

      return undefined;
  }
};
export const category = {
  Umrichter: "INVERTER",
  Inverter: "INVERTER",
  "Umrichter des Speichers": "STORAGE_INVERTER",
  "Storage inverter": "STORAGE_INVERTER",
  Asynchrongenerator: "ASYNCHRONOUS_GENERATOR",
  "Asynchronous generator": "ASYNCHRONOUS_GENERATOR",
  Synchrongenerator: "SYNCHRONOUS_GENERATOR",
  "Synchronous generator": "SYNCHRONOUS_GENERATOR",
  Brennstoffzelle: "FUEL_CELL",
  "Fuel Cell": "FUEL_CELL",
  "Fuel cell": "FUEL_CELL",
  "NA-Schutz": "UNDERVOLTAGE_PROTECTION",
  "NS protection": "UNDERVOLTAGE_PROTECTION",
  "PAV,E-Überwachung": "PAV_E_MONITORING",
  "PAV,E-monitoring": "PAV_E_MONITORING",
  Spannungsregler: "VOLTAGE_REGULATOR",
  "Voltage regulator (interposed)": "VOLTAGE_REGULATOR",
  "Schutzgerät (Zwischengelagert)": "PROTECTIVE_DEVICE_INTERMEDIATE",
  "Protection device": "PROTECTIVE_DEVICE_INTERMEDIATE",
  "EZA-Regler": "EZA_CONTROLLER",
  "PGS controller": "EZA_CONTROLLER",
  Andere: "OTHER",
  Other: "OTHER",
};

const primaryEnergySource = {
  Wind: "WIND",
  PV: "PV",
  "PV / Speicher": "PV_WITH_STORAGE",
  "PV / storage": "PV_WITH_STORAGE",
  "Gasförmige und flüssige Brennstoffe": "OTHER_FUELS",
  "Gaseous and liquid fuels": "OTHER_FUELS",
  Speicher: "STORAGE",
  Storage: "STORAGE",
  Wasser: "WATER",
  Water: "WATER",
  // Biogas: "BIOGAS",
  Sonstiges: "OTHER",
  Other: "OTHER",
  "N/A": "NOT_APPLICABLE",
};

const getCategory: CertificateFieldMappingGetter = ({ value }) =>
  Object.entries(category).find(([v]) => v === value)?.[1];

const getPrimaryEnergySource: CertificateFieldMappingGetter = ({ value }) =>
  Object.entries(primaryEnergySource).find(([v]) => v === value)?.[1];

const convertToISODate: CertificateFieldMappingGetter = ({ value }) => {
  try {
    const date = parse(value, "dd.MM.yyyy", new Date());
    const isoDate = date.toISOString();
    return isoDate;
  } catch (e) {
    try {
      const date = new Date(Date.parse(value));
      const isoDate = date.toISOString();
      return isoDate;
    } catch (e) {
      return undefined;
    }
  }
};

const getTenantsForRole =
  (role: TenantRole): CertificateFieldMappingGetter =>
  async ({ value }) => {
    if (!value || !value.length) return undefined;
    const res = await execGetTenantsForRoleQuery({
      fetchPolicy: "no-cache",
      variables: {
        role,
        inputValue: value,
        approvalStatus: "APPROVED",
      },
    });
    return res.data.tenants?.items?.find((e) => e.tenantName === value)?.id;
  };

const getAuthority: CertificateFieldMappingGetter = async (value) =>
  getTenantsForRole("CERTIFICATION_AUTHORITY")(value);
export const getManufacturer: CertificateFieldMappingGetter = async (value) => getTenantsForRole("MANUFACTURER")(value);

export const booleanGetter: CertificateFieldMappingGetter = ({ value }) =>
  value.toLowerCase() === "ja" || value.toLowerCase() === "yes";

export const excelToCertificateFieldMappings: CertificateFieldMapping[] = [
  { key: "1.01", field: "primaryEnergySource", getter: getPrimaryEnergySource },
  {
    key: "1.02",
    field: "category",
    getter: getCategory,
  },
  { key: "1.03", field: "certificateHolderId", getter: getManufacturer },
  // { key: "1.04", field: "type", label: "Typ" },
  { key: "1.05", field: "certificateIssueDate", getter: convertToISODate },
  {
    key: "1.06",
    field: "certificateBridgeCertificateNormIssueDates",
    getter: getCertificateBridgeCertificateNormIssueDates,
  },
  {
    key: "1.07",
    field: "certificateBridgeCertificateNormIssueDates",
    getter: getCertificateBridgeCertificateNormIssueDates,
  },
  {
    key: "1.08",
    field: "certificateBridgeCertificateNormIssueDates",
    getter: getCertificateBridgeCertificateNormIssueDates,
  },
  {
    key: "1.09",
    field: "certificateBridgeCertificateNormIssueDates",
    getter: getCertificateBridgeCertificateNormIssueDates,
  },
  {
    key: "1.06",
    field: "__normList",
    getter: getNormList,
  },
  {
    key: "1.07",
    field: "__normList",
    getter: getNormList,
  },
  {
    key: "1.08",
    field: "__normList",
    getter: getNormList,
  },
  {
    key: "1.09",
    field: "__normList",
    getter: getNormList,
  },
  // { key: "1.07", field: "certificateNormIssueDateId", label: "Ausgabedatum VDE-AR-N 4110", getter: convertToISODate},
  // { key: "1.08", field: "certificateNormIssueDateId", label: "Ausgabedatum VDE-AR-N 4120",getter: convertToISODate},
  // { key: "1.09", field: "certificateNormIssueDateId", label: "Ausgabedatum VDE-AR-N 4130", getter: convertToISODate},
  { key: "1.10", field: "certificateNumber" },
  { key: "1.11", field: "certificateAuthorityId", getter: getAuthority },
  { key: "1.12", field: "certificateTypeId", getter: certificateTypeGetter },
  {
    key: "1.13",
    field: "certificateValidityStatusId",

    getter: certificateValidityStatusGetter,
  },
  { key: "1.14", field: "validityStartDate", getter: convertToISODate },
  { key: "1.15", field: "validityEndDate", getter: convertToISODate },
  {
    key: "1.16",
    field: "coverSheetAsPDF",

    getter: ({ value }) => value.toLowerCase() === "ja" || value.toLowerCase() === "yes",
  },
  {
    key: "1.17",
    field: "networkInterferenceTestReportAsPDF",

    getter: ({ value }) => value.toLowerCase() === "ja" || value.toLowerCase() === "yes",
  },
  { key: "1.18", field: "restrictions" },
  { key: "1.68", field: "certificateBridgeSoftwareVersions", getter: getsoftwareVersions },
];
