import { Tooltip } from "@mui/material";
import { StyledFooterDocumentsWrapper, StyledFooterSelectionBox, StyledFooterSelectionItem } from "./styles";
import { translations } from "translations";

export type FooterDocumentsContentType = (LinkContentType | MailToContentType | DownloadContentType) & {
  label: string;
  onClick?: React.MouseEventHandler<HTMLAnchorElement>;
};

type LinkContentType = {
  type: "link";
  data?: object;
};

type DownloadContentType = {
  type: "download";
  data: {
    name: string;
    path: string;
  };
};

type MailToContentType = {
  type: "mailto";
  data: {
    email: string;
  };
};

interface FooterDocumentsContentProps {
  content: FooterDocumentsContentType[];
}

export const FooterDocumentsContent = ({ content }: FooterDocumentsContentProps) => (
  <>
    <StyledFooterDocumentsWrapper>
      <StyledFooterSelectionBox>
        {content.map(({ label, onClick, type, data }) => {
          if (type === "link") {
            return (
              <StyledFooterSelectionItem key={label} onClick={onClick}>
                {label}
              </StyledFooterSelectionItem>
            );
          }

          if (type === "mailto") {
            return (
              <Tooltip title={translations.globals.footer.contactTooltip} key={label} placement="right-end">
                <StyledFooterSelectionItem href={`mailto:${data?.email}`} key={label}>
                  {label}
                </StyledFooterSelectionItem>
              </Tooltip>
            );
          }

          if (type === "download") {
            return (
              <Tooltip title={translations.globals.footer.downloadFileTooltip} key={label} placement="right-end">
                <StyledFooterSelectionItem href={process.env.PUBLIC_URL + data.path} key={label} download={data.name}>
                  {label}
                </StyledFooterSelectionItem>
              </Tooltip>
            );
          }
        })}
      </StyledFooterSelectionBox>
    </StyledFooterDocumentsWrapper>
  </>
);
