import styled from "@emotion/styled";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import CloseIcon from "@mui/icons-material/Close";
import { Box, Button, IconButton, Typography } from "@mui/material";
import { dummyIdPrefix } from "pages/UploadCertificate/config";
import {
  getFileTypeIdForFileTypeName,
  uploadCertificateFilesSelectorFamily,
} from "pages/UploadCertificate/state/uploadCertificateFileState";
import { useCallback, useState } from "react";
import { useDropzone } from "react-dropzone";
import { useRecoilState } from "recoil";
import ordinaryColors from "themes/ordinaryColors";
import translations from "translations";
import { v4 as uuidv4 } from "uuid";
import { toBase64 } from "./FileUtils";
import Props from "./index.d";
import UploadedFileIcon from "./UploadedFileIcon";

export const titleFileStyle = {
  width: "20rem",
  color: ordinaryColors.lightTextPrimary,
  fontFeatureSettings: "'clig' off, 'liga' off",
  fontSize: "16px",
  fontStyle: "normal",
  fontWeight: 400,
  lineHeight: "175% /* 28px */",
  letterSpacing: "0.15px",
};
export const StyledUploadButton = styled(Button)(({ theme }) => ({
  height: "43px",
  width: "100%",
  borderRadius: "0px",
  variant: "outlined",
  border: "1px solid",
  borderColor: ordinaryColors.functionalBlack,
  minWidth: "64px",
  color: ordinaryColors.functionalBlack,
}));

const CertificateFileUpload: React.FC<Props> = (props) => {
  const { fileType, title } = props;
  const [fileToUpload, setFileToUpload] = useRecoilState(uploadCertificateFilesSelectorFamily(fileType));
  const [showFileSizeWarning, setShowFileSizeWarning] = useState(false);

  const onDrop = useCallback(
    (acceptedFiles: File[]) => {
      const file = acceptedFiles[0];
      const certificateFileName = file.name;
      const certificateFileTypeId = getFileTypeIdForFileTypeName(fileType);
      const certificateId = `${dummyIdPrefix}${uuidv4()}`;

      if (file.size > 20000000) {
        setShowFileSizeWarning(true);
        return;
      }
      toBase64([file]).then((base64FileString) => {
        setFileToUpload({
          certificateFileName,
          certificateFileTypeId,
          certificateId,
          base64FileString,
          size: file.size,
        });
      });
    },
    [fileType, setFileToUpload]
  );

  const removeFile = () => () => {
    setFileToUpload(undefined);
  };

  const { open, getInputProps } = useDropzone({
    onDrop,
    noDrag: true,
    accept: props.mimeTypes.reduce((acc, mimeType) => ({ ...acc, [mimeType]: [] }), {}),
    multiple: false,
    useFsAccessApi: false,
  });

  if (fileToUpload)
    return (
      <Box>
        <Box sx={{ display: "flex" }}>
          <>
            <input {...getInputProps()} />
            <UploadedFileIcon {...fileToUpload} />
            <Box sx={{ display: "flex" }}>
              <Typography sx={titleFileStyle}>{fileToUpload.certificateFileName}</Typography>
            </Box>
          </>
        </Box>
        <Box sx={{ display: "flex", justifyContent: "flex-end", marginTop: "-40px" }}>
          <IconButton onClick={removeFile()}>
            <CloseIcon />
          </IconButton>
        </Box>
      </Box>
    );
  else
    return (
      <>
        {showFileSizeWarning && (
          <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
            <span style={{ color: ordinaryColors.error }}>
              {translations.pages.certificateUpload.certificateFileTooLarge}
            </span>
            <IconButton onClick={() => setShowFileSizeWarning(false)}>
              <CloseIcon />
            </IconButton>
          </Box>
        )}
        {!showFileSizeWarning && (
          <StyledUploadButton variant="outlined" onClick={open} startIcon={<AttachFileIcon />}>
            <input {...getInputProps()} />
            {title ?? translations.globals.button.uploadGridConnectionValues}
          </StyledUploadButton>
        )}
      </>
    );
};
export default CertificateFileUpload;
