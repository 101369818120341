import { useGridApiContext } from "@mui/x-data-grid-pro";
import { StyledGridToolbarQuickFilter } from "components/templates/dataGridTable/styles";
import React, { useEffect } from "react";
import { atom, useRecoilState } from "recoil";
import translations from "translations";

interface Props {}
export const errorRepportValueAtom = atom<string>({
  key: "errorRepportValueAtom",
  default: "",
});
const ErrorReportQuickFilter: React.FC<Props> = () => {
  const [value, setValue] = useRecoilState(errorRepportValueAtom);
  const apiRef = useGridApiContext();

  useEffect(() => {
    if (!value) return;
    apiRef.current.setQuickFilterValues([value]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [apiRef]);

  return (
    <>
      <StyledGridToolbarQuickFilter
        onChange={(e) => {
          setValue(e.target.value);
        }}
        value={value}
        variant="outlined"
        placeholder={translations.globals.table.searchTableDefault}
      />
    </>
  );
};

export default ErrorReportQuickFilter;
