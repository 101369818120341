import GFCForm_Base from "../../components/formElements/forms/form"
import GFCFieldSet_Accordion from "../../components/formElements/fieldSets/Accordion"
import GFCFieldSet_AccordionFieldWrapper from "../../components/formElements/fieldSets/AccordionFieldWrapper"
import GFCFieldSet_AccordionWrapper from "../../components/formElements/fieldSets/AccordionWrapper"
import GFCFieldSet_CheckModificationType from "../../components/formElements/fieldSets/CheckModificationType"
import GFCFieldSet_Div from "../../components/formElements/fieldSets/Div"
import GFCFieldSet_NoLayoutGroup from "../../components/formElements/fieldSets/NoLayoutGroup"
import GFCFieldSet_NormChecker from "../../components/formElements/fieldSets/NormChecker"
import GFCFieldSet_ShowForRole from "../../components/formElements/fieldSets/ShowForRole"
import GFCFieldSet_UnitFieldWrapper from "../../components/formElements/fieldSets/UnitFieldWrapper"
import GFCFieldSet_UnitRow from "../../components/formElements/fieldSets/UnitRow"
import GFCField_AggregatedFileSize from "../../components/formElements/fields/AggregatedFileSize"
import GFCField_BooleanCheckBox from "../../components/formElements/fields/BooleanCheckBox"
import GFCField_BooleanSwitch from "../../components/formElements/fields/BooleanSwitch"
import GFCField_CancelButton from "../../components/formElements/fields/CancelButton"
import GFCField_CancelIcon from "../../components/formElements/fields/CancelIcon"
import GFCField_CertificateCategory from "../../components/formElements/fields/CertificateCategory"
import GFCField_CertificateFileUpload from "../../components/formElements/fields/CertificateFileUpload"
import GFCField_CertificateFileUploadList from "../../components/formElements/fields/CertificateFileUploadList"
import GFCField_CertificateFileUploadMulti from "../../components/formElements/fields/CertificateFileUploadMulti"
import GFCField_CertificateNormBridge from "../../components/formElements/fields/CertificateNormBridge"
import GFCField_CertificateSoftware from "../../components/formElements/fields/CertificateSoftware"
import GFCField_ConnectCategory from "../../components/formElements/fields/ConnectCategory"
import GFCField_CTASubmitButton from "../../components/formElements/fields/CTASubmitButton"
import GFCField_DatePicker from "../../components/formElements/fields/DatePicker"
import GFCField_DecimalEditor from "../../components/formElements/fields/DecimalEditor"
import GFCField_DropZone from "../../components/formElements/fields/DropZone"
import GFCField_EnumSelector from "../../components/formElements/fields/EnumSelector"
import GFCField_EnumSelectorUnit from "../../components/formElements/fields/EnumSelectorUnit"
import GFCField_ErrorForTextField from "../../components/formElements/fields/ErrorForTextField"
import GFCField_ForeignKeySelector from "../../components/formElements/fields/ForeignKeySelector"
import GFCField_ForeignKeySelectorCertificateTypes from "../../components/formElements/fields/ForeignKeySelectorCertificateTypes"
import GFCField_ForeignKeySelectorStatus from "../../components/formElements/fields/ForeignKeySelectorStatus"
import GFCField_ForeignKeyUnit from "../../components/formElements/fields/ForeignKeyUnit"
import GFCField_FormErrors from "../../components/formElements/fields/FormErrors"
import GFCField_Bridge from "../../components/formElements/fields/GenericBridge"
import GFCField_GenericBridgeChips from "../../components/formElements/fields/GenericBridgeChips"
import GFCField_GenericBridgeMultiSelect from "../../components/formElements/fields/GenericBridgeMultiSelect"
import GFCField_GenericBridgeTable from "../../components/formElements/fields/GenericBridgeTable"
import GFCField_GoToLinkedUnit from "../../components/formElements/fields/GoToLinkedUnit"
import GFCField_IntegerEditor from "../../components/formElements/fields/IntegerEditor"
import GFCField_ManufacturerId from "../../components/formElements/fields/ManufacturerId"
import GFCField_NumberUnit from "../../components/formElements/fields/NumberUnit"
import GFCField_PhoneInput from "../../components/formElements/fields/PhoneInput"
import GFCField_PresubmitButton from "../../components/formElements/fields/PresubmitButton"
import GFCField_StringUnit from "../../components/formElements/fields/StringUnit"
import GFCField_SubmitButton from "../../components/formElements/fields/SubmitButton"
import GFCField_SyncTG8Rev25ComformityWithSharedValues from "../../components/formElements/fields/SyncTG8Rev25ComformityWithSharedValues"
import GFCField_TenantId from "../../components/formElements/fields/TenantId"
import GFCField_TenantIdForRole from "../../components/formElements/fields/TenantIdForRole"
import GFCField_TextArea from "../../components/formElements/fields/TextArea"
import GFCField_TextField from "../../components/formElements/fields/TextField"
import GFCField_TextFieldForPlatformOperators from "../../components/formElements/fields/TextFieldForPlatformOperators"
import GFCField_Title from "../../components/formElements/fields/Title"
import GFCField_UnitErrorsOnCertificate from "../../components/formElements/fields/UnitErrorsOnCertificate"
import GFCField_UnitManufacturer from "../../components/formElements/fields/UnitManufacturer"
import GFCField_VirtualDropZone from "../../components/formElements/fields/VirtualDropZone"
import GFCField_CTACancelButton from "../../components/formElements/customElements/CTACancelButton"
import GFCField_CTAResetButton from "../../components/formElements/customElements/CTAResetButton"
import GFCField_SubmitButtonForEditUser from "../../components/formElements/customElements/SubmitButtonForEditUser"
import GFCField_TenantIdForUser from "../../components/formElements/customElements/TenantIdForUser"

export default [
  {
    Component: GFCForm_Base,
    uiType: "GFCForm_Base",
  },
  {
    Component: GFCFieldSet_Accordion,
    uiType: "GFCFieldSet_Accordion",
  },
  {
    Component: GFCFieldSet_AccordionFieldWrapper,
    uiType: "GFCFieldSet_AccordionFieldWrapper",
  },
  {
    Component: GFCFieldSet_AccordionWrapper,
    uiType: "GFCFieldSet_AccordionWrapper",
  },
  {
    Component: GFCFieldSet_CheckModificationType,
    uiType: "GFCFieldSet_CheckModificationType",
  },
  {
    Component: GFCFieldSet_Div,
    uiType: "GFCFieldSet_Div",
  },
  {
    Component: GFCFieldSet_NoLayoutGroup,
    uiType: "GFCFieldSet_NoLayoutGroup",
  },
  {
    Component: GFCFieldSet_NormChecker,
    uiType: "GFCFieldSet_NormChecker",
  },
  {
    Component: GFCFieldSet_ShowForRole,
    uiType: "GFCFieldSet_ShowForRole",
  },
  {
    Component: GFCFieldSet_UnitFieldWrapper,
    uiType: "GFCFieldSet_UnitFieldWrapper",
  },
  {
    Component: GFCFieldSet_UnitRow,
    uiType: "GFCFieldSet_UnitRow",
  },
  {
    Component: GFCField_AggregatedFileSize,
    uiType: "GFCField_AggregatedFileSize",
  },
  {
    Component: GFCField_BooleanCheckBox,
    uiType: "GFCField_BooleanCheckBox",
  },
  {
    Component: GFCField_BooleanSwitch,
    uiType: "GFCField_BooleanSwitch",
  },
  {
    Component: GFCField_CancelButton,
    uiType: "GFCField_CancelButton",
  },
  {
    Component: GFCField_CancelIcon,
    uiType: "GFCField_CancelIcon",
  },
  {
    Component: GFCField_CertificateCategory,
    uiType: "GFCField_CertificateCategory",
  },
  {
    Component: GFCField_CertificateFileUpload,
    uiType: "GFCField_CertificateFileUpload",
  },
  {
    Component: GFCField_CertificateFileUploadList,
    uiType: "GFCField_CertificateFileUploadList",
  },
  {
    Component: GFCField_CertificateFileUploadMulti,
    uiType: "GFCField_CertificateFileUploadMulti",
  },
  {
    Component: GFCField_CertificateNormBridge,
    uiType: "GFCField_CertificateNormBridge",
  },
  {
    Component: GFCField_CertificateSoftware,
    uiType: "GFCField_CertificateSoftware",
  },
  {
    Component: GFCField_ConnectCategory,
    uiType: "GFCField_ConnectCategory",
  },
  {
    Component: GFCField_CTASubmitButton,
    uiType: "GFCField_CTASubmitButton",
  },
  {
    Component: GFCField_DatePicker,
    uiType: "GFCField_DatePicker",
  },
  {
    Component: GFCField_DecimalEditor,
    uiType: "GFCField_DecimalEditor",
  },
  {
    Component: GFCField_DropZone,
    uiType: "GFCField_DropZone",
  },
  {
    Component: GFCField_EnumSelector,
    uiType: "GFCField_EnumSelector",
  },
  {
    Component: GFCField_EnumSelectorUnit,
    uiType: "GFCField_EnumSelectorUnit",
  },
  {
    Component: GFCField_ErrorForTextField,
    uiType: "GFCField_ErrorForTextField",
  },
  {
    Component: GFCField_ForeignKeySelector,
    uiType: "GFCField_ForeignKeySelector",
  },
  {
    Component: GFCField_ForeignKeySelectorCertificateTypes,
    uiType: "GFCField_ForeignKeySelectorCertificateTypes",
  },
  {
    Component: GFCField_ForeignKeySelectorStatus,
    uiType: "GFCField_ForeignKeySelectorStatus",
  },
  {
    Component: GFCField_ForeignKeyUnit,
    uiType: "GFCField_ForeignKeyUnit",
  },
  {
    Component: GFCField_FormErrors,
    uiType: "GFCField_FormErrors",
  },
  {
    Component: GFCField_Bridge,
    uiType: "GFCField_Bridge",
  },
  {
    Component: GFCField_GenericBridgeChips,
    uiType: "GFCField_GenericBridgeChips",
  },
  {
    Component: GFCField_GenericBridgeMultiSelect,
    uiType: "GFCField_GenericBridgeMultiSelect",
  },
  {
    Component: GFCField_GenericBridgeTable,
    uiType: "GFCField_GenericBridgeTable",
  },
  {
    Component: GFCField_GoToLinkedUnit,
    uiType: "GFCField_GoToLinkedUnit",
  },
  {
    Component: GFCField_IntegerEditor,
    uiType: "GFCField_IntegerEditor",
  },
  {
    Component: GFCField_ManufacturerId,
    uiType: "GFCField_ManufacturerId",
  },
  {
    Component: GFCField_NumberUnit,
    uiType: "GFCField_NumberUnit",
  },
  {
    Component: GFCField_PhoneInput,
    uiType: "GFCField_PhoneInput",
  },
  {
    Component: GFCField_PresubmitButton,
    uiType: "GFCField_PresubmitButton",
  },
  {
    Component: GFCField_StringUnit,
    uiType: "GFCField_StringUnit",
  },
  {
    Component: GFCField_SubmitButton,
    uiType: "GFCField_SubmitButton",
  },
  {
    Component: GFCField_SyncTG8Rev25ComformityWithSharedValues,
    uiType: "GFCField_SyncTG8Rev25ComformityWithSharedValues",
  },
  {
    Component: GFCField_TenantId,
    uiType: "GFCField_TenantId",
  },
  {
    Component: GFCField_TenantIdForRole,
    uiType: "GFCField_TenantIdForRole",
  },
  {
    Component: GFCField_TextArea,
    uiType: "GFCField_TextArea",
  },
  {
    Component: GFCField_TextField,
    uiType: "GFCField_TextField",
  },
  {
    Component: GFCField_TextFieldForPlatformOperators,
    uiType: "GFCField_TextFieldForPlatformOperators",
  },
  {
    Component: GFCField_Title,
    uiType: "GFCField_Title",
  },
  {
    Component: GFCField_UnitErrorsOnCertificate,
    uiType: "GFCField_UnitErrorsOnCertificate",
  },
  {
    Component: GFCField_UnitManufacturer,
    uiType: "GFCField_UnitManufacturer",
  },
  {
    Component: GFCField_VirtualDropZone,
    uiType: "GFCField_VirtualDropZone",
  },
  {
    Component: GFCField_CTACancelButton,
    uiType: "GFCField_CTACancelButton",
  },
  {
    Component: GFCField_CTAResetButton,
    uiType: "GFCField_CTAResetButton",
  },
  {
    Component: GFCField_SubmitButtonForEditUser,
    uiType: "GFCField_SubmitButtonForEditUser",
  },
  {
    Component: GFCField_TenantIdForUser,
    uiType: "GFCField_TenantIdForUser",
  },
]
