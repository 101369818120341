import styled from "@emotion/styled";
import { Typography } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import ordinaryColors from "themes/ordinaryColors";
import translations from "translations";
import UnitsAndCertificatesLink from "pages/CertificationManagement/UnitsOverview/UnitsAndCertificatesLink";
interface StatusTagProps {
  title: string;
  backgroundColor?: string;
  color: string;
  borderColor?: string;
  replacedByCertificate?: { certificateNumber: string; id: string };
}

export const StatusTagBox = styled(Box)<{ backgroundColor?: string; borderColor?: string }>(
  ({ backgroundColor, borderColor }) => ({
    display: "flex",
    padding: "0.125rem 0.75rem",
    alignItems: "center",
    justifyContent: "center",
    minWidth: "75px",
    maxWidth: "100%",
    gap: "8px",
    borderRadius: "0.5rem",
    border: `0.0625rem solid ${borderColor ?? ordinaryColors.grey400}`,
    backgroundColor: backgroundColor ?? ordinaryColors.functionalWhite,
  })
);

const StatusTag: React.FC<StatusTagProps> = ({ title, backgroundColor, color, borderColor, replacedByCertificate }) => {
  const currentLanguage = translations.getLanguage();
  const replacedByCertificateTitle = replacedByCertificate ? title.slice(0, -1) : title;

  return (
    <StatusTagBox backgroundColor={backgroundColor} borderColor={borderColor}>
      {replacedByCertificate ? (
        <Typography
          lang={currentLanguage}
          sx={{ color: color, fontSize: "1rem", fontWeight: "600", hyphens: "auto", wordBreak: "break-word" }}
        >
          {replacedByCertificateTitle}{" "}
          <UnitsAndCertificatesLink
            label={replacedByCertificate.certificateNumber}
            path={`/certificates/${replacedByCertificate.id}`}
          />
          {" )"}
        </Typography>
      ) : (
        <Typography
          lang={currentLanguage}
          sx={{ color: color, fontSize: "1rem", fontWeight: "600", hyphens: "auto", wordBreak: "break-word" }}
        >
          {title}
        </Typography>
      )}
    </StatusTagBox>
  );
};

export default StatusTag;
