import useDataGrid from "components/templates/dataGridTable";
import {
  GetCertificatesToValidateQuery,
  GetCertificatesToValidateQueryVariables,
  useGetCertificatesToValidateLazyQuery,
} from "graphqlBase/issue/__generated__/getCertificatesToValidate";
import { PaginatedResultType } from "graphqlBase/types";
import { FC, MouseEvent, useEffect, useMemo, useState } from "react";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import { activeTaskSelectionState, refetchRecoil } from "../recoil";

import { Popover } from "@mui/material";
import { getGridStringOperators } from "@mui/x-data-grid-pro";
import PopoverForSort from "components/molecules/PopoverForSort";
import { execGetValidatedTenantsForRoleQuery } from "graphqlBase/tenant/__generated__/getValidatedTenantsForRole";
import { translations } from "translations";
import { customStyle, StyledOpenTaskListContainer } from "../styles";
import { ActiveTaskSelection } from "../types";
import CustomOpenTaskFilters from "./CustomOpenTaskFilters";
import {
  filterValueLengthAtom,
  openTaskListFilterAtom,
  openTaskListLengthAtom,
} from "./CustomOpenTaskFilters/State/manageFiltersInOpenTaskList";
import getIdsForFilterValues from "./CustomOpenTaskFilters/utils/getIdsForFilterValues";
import { useCustomFilterbarForOpenTasks } from "./CustomOpenTaskFilters/utils/useCustomFilterbarForOpenTasks";
import { useSortDataByIssueTypeName } from "./CustomOpenTaskFilters/utils/useSortDataByIssueTypeName";
import TaskRow from "./TaskRow";

interface Props {
  tenantId: string;
}

export type Issue = PaginatedResultType<GetCertificatesToValidateQuery["issues"]>;

const buildActiveTaskSelection = ({ tenantId, issue }: { tenantId: string; issue: Issue }): ActiveTaskSelection => {
  const { certificateId, id: issueId, issueStatusId, issueTypeId, certificate, createdBy } = issue;
  return {
    certificateId,
    issueId,
    issueStatusId,
    issueTypeId,
    edited: false,
    uploaderId: certificate.createdByTenant,
    certificateHolderId: certificate.certificateHolderId,
    certificateAuthorityId: certificate.certificateAuthorityId,
    isUploader: tenantId === certificate.createdByTenant,
    createdByUserId: createdBy ?? "",
    issueTypeName: issue.issueType.issueTypeName,
    certificateNumber: certificate.certificateNumber,
    isImported: certificate.isImported,
    transformedBy: certificate.transformedBy,
    transformedOn: certificate.transformedOn,
    certificateUnits: certificate.certificateBridgeUnits.map((unit) => unit.unitId),
  };
};

const getSortingOptions = () => ({
  fields: [
    { title: translations.entities.certificate.createdAt, value: "createdAt" },
    { title: translations.pages.certificateValidation.issueTypesLabel, value: "issueTypeName" },
    { title: translations.entities.certificate.manufacturer, value: "manufacturer" },
  ],
  orders: [translations.enums.sort.ASC, translations.enums.sort.DESC],
});

export const certificatesToValidateIssueStatusCondition = {
  issueStatus: {
    issueStatusName: {
      or: [
        { eq: "Open" },
        { eq: "Reopened" },
        { eq: "Rejected" },
        { eq: "Pending Review" },
        { eq: "New" },
        { eq: "In Progress" },
      ],
    },
  },
};

const OpenTaskList: FC<Props> = ({ tenantId }) => {
  const setAtiveTaskSelection = useSetRecoilState(activeTaskSelectionState);
  const [currentClientSideSortValue, setCurrentClientSideSort] = useState<string>(translations.enums.sort.DESC);
  const filterValues = useRecoilValue(openTaskListFilterAtom);
  const [filterValue, setFilterValue] = useState<boolean>(false);
  const [sortAnchorEl, setSortAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [filterAnchorEl, setFilterAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [noDataMessage, setNoDataMessage] = useState<string>();
  const [sort, setSort] = useState(translations.enums.sort.DESC);
  const lengthOfFilters = useRecoilValue(filterValueLengthAtom);
  const [currentsort, setCurrentSort] = useState<{ [x: string]: string }>({ createdAt: "DESC" });
  // const [selectedValue, setSelectedValue] = useState<string>("issueTypeName");
  const [selectedValue, setSelectedValue] = useState<string>("createdAt");
  const [openTaskListLength, setOpenTaskListLength] = useRecoilState(openTaskListLengthAtom);
  const variables: GetCertificatesToValidateQueryVariables = useMemo(
    () => ({
      where: {
        ...certificatesToValidateIssueStatusCondition,
        assignedToTenantId: { eq: tenantId },
      },
      order: currentsort,
    }),
    [currentsort, tenantId]
  );
  const [queryPre, { data, error, loading, refetch }] = useGetCertificatesToValidateLazyQuery({
    fetchPolicy: "network-only",
    variables,
  });
  const sortDataByIssueTypeName = useSortDataByIssueTypeName(
    data?.issues?.items ?? [],
    currentClientSideSortValue,
    selectedValue
  );

  const query = useCustomFilterbarForOpenTasks(queryPre, filterValues);
  useEffect(() => {
    setOpenTaskListLength(data?.issues?.totalCount);
  }, [data?.issues?.totalCount, setOpenTaskListLength]);

  useEffect(() => {
    query({ variables });
  }, [query, variables]);

  const setRefetch = useSetRecoilState(refetchRecoil("OpenTaskList"));

  useEffect(() => {
    setRefetch(() => async () => {
      await refetch();
    });
  }, [setRefetch, refetch]);

  const handleRowClick = (params: { row: Issue }) => {
    setAtiveTaskSelection((prev) => ({
      ...buildActiveTaskSelection({ tenantId, issue: params.row }),
      comment: "",
      persistMode: undefined,
    }));
  };

  useEffect(() => {
    if (!data?.issues?.items?.[0]) return;
    const issue = data.issues.items[0];
    setAtiveTaskSelection(
      buildActiveTaskSelection({
        tenantId,
        issue,
      })
    );
  }, [data, setAtiveTaskSelection, tenantId]);

  const handleSortApply = (sort: string, fieldValue: string) => {
    setSelectedValue(fieldValue);
    setSort(sort);
    if (fieldValue === "createdAt") {
      setCurrentSort({ ["createdAt"]: sort === translations.enums.sort.ASC ? "ASC" : "DESC" });
    }
    setCurrentClientSideSort(sort);
    setSortAnchorEl(null);
  };
  const handleSortAnchor = (event: MouseEvent<HTMLButtonElement>) => {
    setSortAnchorEl(event.currentTarget);
  };
  const handleFilterAnchor = (event: MouseEvent<HTMLButtonElement>) => {
    setFilterAnchorEl(event.currentTarget);
  };

  useEffect(() => {
    const noDataAvailableOrNoResultMessage =
      (lengthOfFilters && lengthOfFilters > 0) || !filterValue
        ? translations.globals.notifications.noResultsAvailable
        : translations.globals.notifications.noDataAvailable;
    setNoDataMessage(noDataAvailableOrNoResultMessage);
  }, [lengthOfFilters, filterValue]);
  const sortOpen = Boolean(sortAnchorEl);
  const filterOpen = Boolean(filterAnchorEl);
  const sortPopoverId = sortOpen ? "sort-popover" : undefined;
  const filterPopoverId = filterOpen ? "filter-popover" : undefined;

  const handleClose = () => {
    setSortAnchorEl(null);
    setFilterAnchorEl(null);
  };
  const { DataGrid, useMakeColumns, apiRef, makeSeverSideFilter } = useDataGrid<Issue, "Issue">({
    query,
    variables,
    tableId: "OpenTasks",
  });

  const columns = useMakeColumns(
    [
      {
        field: "taskType",
        headerName: `taskType`,
        flex: 1,
        renderHeader: () => null,

        renderCell: ({ row }) => <TaskRow row={row} />,
        type: "string",
        disableColumnMenu: true,
        disableReorder: true,
        resizable: false,
        remoteFilter: makeSeverSideFilter("string", {
          filterOperators: getGridStringOperators().filter(
            (operator) =>
              operator.value === "equals" || // Ensure this line is included
              operator.value === "contains" ||
              operator.value === "startsWith" ||
              operator.value === "endsWith"
          ),

          filterPath: async ({ where, filterValue, quickValues }) => {
            setFilterValue(!filterValue);
            if (!quickValues?.length && !filterValue) return;
            const { idsForQuickValues } = await getIdsForFilterValues(
              // filterValue ?? [],
              quickValues ?? []
            );
            const joinedQuickValues = quickValues?.join(" ") ?? "";
            const elems = await execGetValidatedTenantsForRoleQuery({
              variables: { inputValue: joinedQuickValues, role: "MANUFACTURER", approvalStatus: "PENDING" },
            });
            const ids = elems.data?.tenants?.items?.map<string>((item) => item.id);

            if (ids !== undefined && ids.length > 0 && !idsForQuickValues?.length)
              return {
                certificate: {
                  certificateHolderId: { in: ids },
                },
              };

            if (!idsForQuickValues?.length && quickValues?.length) {
              return {
                certificate: {
                  certificateNumber: { contains: joinedQuickValues },
                },
              };
            }

            return {
              issueTypeId: {
                in: idsForQuickValues,
              },
            };
          },
        }),
      },
    ],
    [openTaskListLength, lengthOfFilters]
  );

  return (
    <StyledOpenTaskListContainer>
      <DataGrid
        customToolBarProps={{ isListToolbar: true, handleSortAnchor, handleFilterAnchor }}
        hasToolbar
        localeText={{
          toolbarColumns: "Columns",
          toolbarFilters: "",
        }}
        sx={customStyle}
        loading={loading}
        error={error}
        columns={columns}
        rows={sortDataByIssueTypeName}
        noDataMessage={noDataMessage}
        tableHeight="100%"
        tableTitle="Open Tasks"
        apiRef={apiRef}
        rowHeight={46}
        handleOnRowClick={handleRowClick}
        hideFooter
        hideFooterSelectedRowCount
        checkboxSelection={false}
        showColumnRightBorder
        pageSize={1000}
        headerHeight={0}

        // autoHeight
      />
      <Popover
        id={sortPopoverId}
        open={sortOpen}
        anchorEl={sortAnchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <PopoverForSort
          title={translations.globals.button.sort}
          selectedValue={selectedValue}
          sort={sort}
          handleSortApply={handleSortApply}
          sortingOptions={getSortingOptions()}
        />
      </Popover>
      <Popover
        id={filterPopoverId}
        open={filterOpen}
        anchorEl={filterAnchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <CustomOpenTaskFilters />
      </Popover>
    </StyledOpenTaskListContainer>
  );
};

export default OpenTaskList;
