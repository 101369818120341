import { subscript } from "components/mlpaUiComponents/LegalNote/lib";

export default {
  category: "Category/Class/Equipment",
  certificateCode: "ZEREZ ID for Certificate",
  certificateIssueDate: "Certificate Issue Date",
  certificateNorm: "Norm",
  certificateNumber: "Certificate Number",
  certificateType: "Type Of Verification Document",
  certificateValidity: "Certificate Validity",
  certificateValidityStatus: "Validity Status",
  // certificationBody: "Certification Body",
  certifyingAuthority: "Certification Body",
  // class: "Category/Class/Equipment",
  createdAt: "Created at",
  createdBy: "Uploaded By",
  fastVoltageChangeTurnOffAtRatedPower: "Disconnection At Rated Power ",
  fastVoltageChangeTurnOnAtRatedConditions: "Connection At Nominal Conditions (Of The Primary Energy Carrier)",
  fastVoltageChangeTurnOnWithoutSpecifications: "Connection Without Provisions (Regarding The Primary Energy Carrier)",
  fastVoltageChangeWorstCaseDuringGeneratorStageSwitching: "Most Adverse Case When Switching Between Generator Levels",
  importedAt: "Imported At",
  importedBy: "Imported By",
  initialShortCircuitAlternatingCurrent: "Initial Short-Circuit Current",
  kpiTitle: "Total Certificates",
  manufacturer: "Manufacturer",
  maxActivePower: "Maximum Active Power",
  maxApparentPower: "Maximum Apparent Power",
  hasPowerRange: "Has Power Range",
  minPowerRange: "Minimum Power Range",
  maxPowerRange: "Maximum Power Range",
  powerRange: "Power Range",
  hasActivePowerRange: "Maximum Active Power Range",
  hasApparentPowerRange: "Maximum Apparent Power Range",
  maxActivePowerRange: "Maximum Active Power Range Maximum",
  minActivePowerRange: "Maximum Active Power Range Minimum",
  maxApparentPowerRange: "Maximum Apparent Power Range Maximum",
  minApparentPowerRange: "Maximum Apparent Power Range Minimum",
  maxActivePower09: `Maximum Active Power of the generation unit at 0.9 U${subscript("n")}`,
  // name: "Certificate",
  // netFeedIn: "Grid Connection",
  modifiedAt: "Last Edit",
  modifiedBy: "Edited By",
  name: "Certificates",
  netFeedIn: "Grid Connection",
  operation1FlickerStepFactor30: `Flicker Step Factor k${subscript("f(Ψ)")}, 30°`,
  operation1FlickerStepFactor50: `Flicker Step Factor k${subscript("f(Ψ)")}, 50°`,
  operation1FlickerStepFactor70: `Flicker Step Factor k${subscript("f(Ψ)")}, 70°`,
  operation1FlickerStepFactor85: `Flicker Step Factor k${subscript("f(Ψ)")}, 85°`,
  operation1MaxNumberN120: `Max. No. Of Switching Operations N${subscript("120")}`,
  operation1MaxSwitchN10: `Max. No. Of Switching Operations N${subscript("10")}`,
  operation1VoltageVariationFactor30: `Voltage Variation Factor k${subscript("u(Ψ)")}, 30°`,
  operation1VoltageVariationFactor50: `Voltage Variation Factor k${subscript("u(Ψ)")}, 50°`,
  operation1VoltageVariationFactor70: `Voltage Variation Factor k${subscript("u(Ψ)")}, 70°`,
  operation1VoltageVariationFactor85: `Voltage Variation Factor k${subscript("u(Ψ)")}, 85°`,
  operation2FlickerStepFactor30: `Flicker Step Factor k${subscript("f(Ψ)")}, 30°`,
  operation2FlickerStepFactor50: `Flicker Step Factor k${subscript("f(Ψ)")}, 50°`,
  operation2FlickerStepFactor70: `Flicker Step Factor k${subscript("f(Ψ)")}, 70°`,
  operation2FlickerStepFactor85: `Flicker Step Factor k${subscript("f(Ψ)")}, 85°`,
  operation2MaxNumberN120: `Max. No. Of Switching Operations N${subscript("120")}`,
  operation2MaxSwitchN10: `Max. No. Of Switching Operations N${subscript("10")}`,
  operation2VoltageVariationFactor30: `Voltage Variation Factor k${subscript("u(Ψ)")}, 30°`,
  operation2VoltageVariationFactor50: `Voltage Variation Factor k${subscript("u(Ψ)")}, 50°`,
  operation2VoltageVariationFactor70: `Voltage Variation Factor k${subscript("u(Ψ)")}, 70°`,
  operation2VoltageVariationFactor85: `Voltage Variation Factor k${subscript("u(Ψ)")}, 85°`,
  operation3FlickerStepFactor30: `Flicker Step Factor k${subscript("f(Ψ)")}, 30°`,
  operation3FlickerStepFactor50: `Flicker Step Factor k${subscript("f(Ψ)")}, 50°`,
  operation3FlickerStepFactor70: `Flicker Step Factor k${subscript("f(Ψ)")}, 70°`,
  operation3FlickerStepFactor85: `Flicker Step Factor k${subscript("f(Ψ)")}, 85°`,
  operation3MaxNumberN120: `Max. No. Of Switching Operations N${subscript("120")}`,
  operation3MaxSwitchN10: `Max. No. Of Switching Operations N${subscript("10")}`,
  operation3VoltageVariationFactor30: `Voltage Variation Factor k${subscript("u(Ψ)")}, 30°`,
  operation3VoltageVariationFactor50: `Voltage Variation Factor k${subscript("u(Ψ)")}, 50°`,
  operation3VoltageVariationFactor70: `Voltage Variation Factor k${subscript("u(Ψ)")}, 70°`,
  operation3VoltageVariationFactor85: `Voltage Variation Factor k${subscript("u(Ψ)")}, 85°`,
  operationAllWorstCase: `Worst Case Of All Switching Operation k${subscript("i max")}`,
  primaryEnergy: "Primary Energy",
  primaryEnergySource: "Primary Energy Source",
  ratedActivePower: "Rated Active Power",
  ratedApparentPower: "Rated Apparent Power",
  ratedCurrent: "Rated Current",
  ratedVoltage: "Rated Voltage",
  restrictions: "Restrictions",
  revision: "Revision",
  replacedByCertificate: "Replaced by Certificate",
  replacedCertificate: "Follows Certificate",
  shortCircuitPeakCurrent: "Short Whithstand Current",
  systemFlickerCoefficientCY30: `Flicker, System Flicker Coefficient c${subscript("Ψ")}, 30°`,
  systemFlickerCoefficientCY50: `Flicker, System Flicker Coefficient c${subscript("Ψ")}, 50°`,
  systemFlickerCoefficientCY70: `Flicker, System Flicker Coefficient c${subscript("Ψ")}, 70°`,
  systemFlickerCoefficientCY85: `Flicker, System Flicker Coefficient c${subscript("Ψ")}, 85°`,
  uploaded: "Uploaded Certificate",
};
