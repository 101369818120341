import ArrowDownwardOutlinedIcon from "@mui/icons-material/ArrowDownwardOutlined";
import ArrowUpwardOutlinedIcon from "@mui/icons-material/ArrowUpwardOutlined";
import { getTranslatedSortString } from "lib/helper/getTranslatedRowDataStrings";

const arrowIconStyle = { width: "24px", height: "24px" };

const CurrentSortIcon = ({
  value,
  sort,
  activeAscDesc,
  isUnitsOverviewSorting,
}: {
  value: string;
  sort?: string;
  activeAscDesc?: boolean;
  isUnitsOverviewSorting?: boolean;
}) => {
  const ArrowIcon =
    value === getTranslatedSortString("ASC") || value === "ASC" ? ArrowUpwardOutlinedIcon : ArrowDownwardOutlinedIcon;

  return (
    <ArrowIcon
      sx={(theme) => ({
        ...arrowIconStyle,
        color: isUnitsOverviewSorting
          ? theme.palette.ordinaryColors.background
          : value.toUpperCase() === sort?.toUpperCase() && activeAscDesc
          ? theme.palette.ordinaryColors.background
          : theme.palette.ordinaryColors.silverChalice,
      })}
    />
  );
};

export default CurrentSortIcon;
