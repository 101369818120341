import { Box, styled, Typography } from "@mui/material";
import ordinaryColors from "themes/ordinaryColors";

export const StyledFooterRoot = styled(Box)(
  () => ({
    zIndex: 1,
    display: "flex",
    width: "100%", // "2788px"
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    borderTop: `1px solid ${ordinaryColors.functionalBlack900}`,
    background: ordinaryColors.functionalWhite,

    minWidth: "48rem",
  }),
  { label: "StyledFooterRoot" }
);

export const StyledFooterContainer = styled(Box)(
  () => ({
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    alignSelf: "stretch",
  }),
  { label: "StyledFooterContainer" }
);

export const StyledFooterInfoBoxContainer = styled(Box)(
  () => ({
    paddingTop: "1.5rem",
    position: "relative",
    marginLeft: "auto",
    marginRight: "auto",
    // padding: "2rem 1rem",
    // width: "80rem", // "1857px
    display: "flex",
    // padding: "2rem 0",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    // gap: "10px",
    "@media (max-width: 1350px)": {
      // width: "58rem",
      padding: "2rem 1rem",
    },
    "@media (max-width: 992px)": {
      // width: "48rem", // "1857px",
      padding: "2rem 1rem",
    },
  }),
  { label: "StyledFooterInfoBoxContainer" }
);

export const StyledFooterInfoBoxWrapper = styled(Box)(
  () => ({
    gap: "4rem", // "32px
    // marginTop: "2rem",
    display: "flex", // "1857px",
    width: "100%",
    justifyContent: "space-between ",
    // alignItems: "center",
    flexDirection: "row",
    background: ordinaryColors.functionalWhite,
  }),
  { label: "StyledFooterInfoBoxWrapper" }
);

export const StyledFooterInfoBox = styled(Box)(
  ({ margin, alignItems }: { margin?: string | number; alignItems?: string | undefined }) => ({
    display: "flex",
    flexDirection: "row",
    alignItems: alignItems ? alignItems : "flex-start",
    gap: "1.5rem",
    // maxWidth: "50rem", // "1855px",
  }),
  { label: "StyledFooterInfoBox" }
);

export const StyledFooterInfoWrapper = styled(Box)(
  ({ fontWeight }: { fontWeight: number }) => ({
    color: ordinaryColors.functionalBlack1000,
    fontFeatureSettings: "'clig' off, 'liga' off",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight,
    lineHeight: "143% /* 25.74px */",
    letterSpacing: "0.17px",
    alignItems: "center",
    gap: "8px",
  }),
  { label: "StyledFooterInfoWrapper" }
);

export const StyledFooterInfoContentWrapper = styled(Box)(
  {
    flexDirection: "column",
  },
  { label: "StyledFooterInfoContentWrapper" }
);

export const StyledFooterInfo = styled(Box)(
  ({ width }: { width?: string }) =>
    () => ({
      minWidth: "fit-content",
      maxWidth: "13rem",
      display: "flex",
      alignItems: "center",
      gap: "8px",
    }),
  { label: "StyledFooterInfo" }
);

export const StyledDivider = styled(Box)(
  () => ({
    width: "100%", // "1855px",
    height: "0.0625rem",
    background: ordinaryColors.grey300 ?? "#E0E0E0",
  }),
  { label: "StyledDivider" }
);

export const StyledFooterDocumentsContainer = styled(Box)(
  () => ({
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    gap: "10px",
    alignSelf: "stretch",
    background: ordinaryColors.functionalWhite,
    marginLeft: "auto",
    marginRight: "auto",
    width: "100%", // "1857px
    padding: "2rem 1rem",

    "@media (max-width: 1350px)": {
      // width: "58rem",
    },
    "@media (max-width: 992px)": {
      width: "48rem", // "1857px",
    },
  }),
  { label: "StyledFooterDocumentsContainer" }
);

export const StyledFooterDocumentsWrapper = styled(Box)(
  () => ({
    display: "flex",

    // padding: "2rem 2rem", // "1857px",
    width: "100%", // "1857px",
    justifyContent: "center",
    alignItems: "center",
  }),
  { label: "StyledFooterDocumentsWrapper" }
);

export const StyledFooterCopyrightBox = styled(Box)(
  () => ({
    color: "transparent", // ordinaryColors.unnamed7,
    fontFeatureSettings: "'clig' off, 'liga' off",
    fontSize: "18px",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "143% /* 25.74px */",
    letterSpacing: "0.17px",
    alignSelf: "flex-end  ",
  }),
  { label: "StyledFooterCopyrightBox" }
);

export const StyledFooterSelectionBox = styled(Box)(
  () => ({
    display: "flex",
    alignItems: "flex-start",
    gap: "32px",
  }),
  { label: "StyledFooterSelectionBox" }
);

export const StyledFooterSelectionItem = styled("a")(
  () => ({
    cursor: "pointer",
    color: ordinaryColors.primaryBrand,
    fontFeatureSettings: "'clig' off, 'liga' off",
    fontSize: "1rem",
    fontStyle: "normal",
    fontWeight: 600,
    lineHeight: "143% /* 25.74px */",
    letterSpacing: "0.17px",
    textDecoration: "none",
  }),
  { label: "StyledFooterSelectionItem" }
);

export const StyledFooterHeaderBox = styled(Box)(
  () => ({
    display: "flex",
    // padding: "2rem 2rem 0",
    alignItems: "baseline",
    justifyContent: "center",
    gap: "1rem",
  }),
  { label: "StyledFooterSelectionItem" }
);

export const StyledFooterTitleTypography = styled(Typography)(
  () => ({
    color: ordinaryColors.grey900 ?? `#212121`,
    fontFeatureSettings: "'liga' off, 'clig' off",
    fontSize: "1rem",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "143% /* 1.43rem */",
    letterSpacing: "0.010625rem",
    textWrap: "nowrap",
  }),
  { label: "StyledFooterTitleTypography" }
);
