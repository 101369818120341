export default {
  kpiTitle: "Total units registered",
  modelName: "Type / Model Name",
  // name: "Unit",
  // pluralName: "Units",
  // ratedActivePower: "Rated active power",
  // ratedApparentPower: "Rated apparent power",
  // ratedCurrent: "Rated Current",
  searchUnits: "Search units",
  unitNumber: "ZEREZ ID for unit",
  unitTypeName: "Category/Class/Equipment",
};
