import { useGridApiContext } from "@mui/x-data-grid-pro";
import { StyledGridToolbarQuickFilter } from "components/templates/dataGridTable/styles";
import React, { useEffect, useState } from "react";
import translations from "translations";

interface Props {}

const UnitsQuickFilter: React.FC<Props> = () => {
  const [value, setValue] = useState<string | undefined>();
  const apiRef = useGridApiContext();

  useEffect(() => {
    if (!value) return;

    apiRef.current.setQuickFilterValues([value]);
  }, [value, apiRef]);

  return (
    <>
      <StyledGridToolbarQuickFilter
        onBlur={(e) => {
          setValue(e.target.value || undefined);
        }}
        variant="outlined"
        placeholder={translations.globals.table.searchTableDefault}
      />
    </>
  );
};

export default UnitsQuickFilter;
