import { Box } from "@mui/system";
import { ReactComponent as BMWKLogo } from "components/icons/iconsSvg/BMWK_Logo.svg";
import { useNavigate } from "react-router-dom";
import translations from "translations";
import { FooterDocumentsContent, FooterDocumentsContentType } from "./FooterDocumentsContent";
import FooterHeader from "./FooterHeader";
import { FooterInfoBoxContent, FooterInfoBoxContentProps } from "./FooterInfoBoxContent";
import {
  StyledFooterContainer,
  StyledFooterDocumentsContainer,
  StyledFooterInfoBoxContainer,
  StyledFooterRoot,
} from "./styles";

const getFoundedByContent = (): FooterInfoBoxContentProps => [
  {
    headerTitle: (
      <Box>
        <Box>{translations.globals.footer.fundedBy}</Box>
        <Box
          style={{ width: "100%", maxWidth: translations.getLanguage() === "en" ? "245px" : "324px" }}
          dangerouslySetInnerHTML={{ __html: translations.globals.footer.officiallyAppointed }}
        />
      </Box>
    ),

    content: [
      {
        icon: (
          <Box sx={{ display: "flex", flexDirection: "column" }}>
            <BMWKLogo />
          </Box>
        ),
      },
    ],
  },
];

const getDocumentsContent = ({
  handleImprint,
  handleFaq,
}: {
  handleImprint?: React.MouseEventHandler<HTMLAnchorElement>;
  handleFaq?: React.MouseEventHandler<HTMLAnchorElement>;
}): FooterDocumentsContentType[] => {
  return [
    { label: translations.globals.footer.imprint, onClick: handleImprint ?? (() => null), type: "link" },
    { label: translations.globals.footer.faq, onClick: handleFaq ?? (() => null), type: "link" },
    {
      label: translations.globals.footer.privacyPolicy,
      type: "download",
      data: {
        name: translations.globals.footer.privacyPolicy,
        path:
          translations.getLanguage() === "en"
            ? "documents/ZEREZ_Datenschutz_Rev0_en.pdf"
            : "documents/ZEREZ_Datenschutz_Rev0_dt.pdf",
      },
    },
    {
      label: translations.globals.footer.eula,
      type: "download",
      data: {
        name: translations.globals.footer.eula,
        path:
          translations.getLanguage() === "en"
            ? "documents/250131_Nutzungsbedingungen_ZEREZ_en.pdf"
            : "documents/250131_Nutzungsbedingungen ZEREZ.pdf",
      },
    },
    {
      label: translations.globals.footer.contact,
      type: "mailto",
      data: {
        email: "info@zerez.net",
      },
    },
  ];
};

const Footer = () => {
  const navigate = useNavigate();
  const handleFaq = () => navigate("/faq");
  const handleImprint = () => navigate("/imprint");

  const FooterMiddlePart = () => {
    return (
      <>
        <StyledFooterContainer>
          <FooterHeader />
          <StyledFooterInfoBoxContainer>
            <FooterDocumentsContent content={getDocumentsContent({ handleFaq, handleImprint })} />
          </StyledFooterInfoBoxContainer>
        </StyledFooterContainer>
      </>
    );
  };

  return (
    <StyledFooterRoot>
      <StyledFooterContainer>
        <StyledFooterDocumentsContainer>
          <FooterInfoBoxContent content={getFoundedByContent()} FooterMiddlePart={<FooterMiddlePart />} />
        </StyledFooterDocumentsContainer>
      </StyledFooterContainer>
    </StyledFooterRoot>
  );
};

export default Footer;
