import { atom, DefaultValue, selectorFamily } from "recoil";

export interface UnitsOverviewFilterProps {
  certificateHolderId: string[];
  certificateAuthorityId: string[];

  certificateNorms: string[];
  certificateValidityStatus: string[];
  documentType: string[];
  hasZerezId: boolean;
  isVerified: boolean;
  // manufacturer: string[];
  maxActivePower: number[] | null;
  primaryEnergySource: string[];
  categoryClassEquipment: string[];
  ratedCurrent: number[];
  ratedVoltage: number[];
  tenantId: string;
  isNotReplaced: boolean;
  subscribed: string[];
  createdAt: string;
  modifiedAt: string;
}

export type EnumMultiSelectProps = {
  primaryEnergySource: string[];
  categoryClassEquipment: string[];
};

export type SortingDropDownProps = {
  createdAt: string;
  modifiedAt: string;
};

export type QueryMultiSelectProps = {
  documentType: string[];
  certificateValidityStatus: string[];
  certificateNorms: string[];
  certificateHolderId: string[];
  certificateAuthorityId: string[];
  subscribed: string[];
};

export type RangeSliderProps = {
  maxActivePower: number[];
  ratedCurrent: number[];
  ratedVoltage: number[];
};

export type ToggleFiltersProps = {
  hasZerezId: boolean;
  isVerified: boolean;
  isNotReplaced: boolean;
};

export type ToggleStringFiltersProps = {
  tenantId: string;
};

export const unitsOverviewFilterAtom = atom<UnitsOverviewFilterProps>({
  key: "unitsOverviewFilterAtom",
  default: {
    certificateHolderId: [],
    certificateAuthorityId: [],

    certificateNorms: [],
    certificateValidityStatus: [],
    documentType: [],
    isVerified: false,
    hasZerezId: false,
    // manufacturer: [],
    maxActivePower: [0, 5000],
    primaryEnergySource: [],
    categoryClassEquipment: [],
    ratedCurrent: [0, Infinity],
    ratedVoltage: [0, Infinity],
    tenantId: "",
    createdAt: "",
    isNotReplaced: true,
    subscribed: [],
    modifiedAt: "",
  },
});

export const enumFilterSelectorFamily = selectorFamily<string[], keyof EnumMultiSelectProps>({
  key: "enumFilterSelectorFamily",
  get:
    (filterType) =>
    ({ get }) => {
      const filterValues = get(unitsOverviewFilterAtom);
      return filterValues[filterType] ?? [];
    },

  set:
    (filterType) =>
    ({ set }, newValue) => {
      if (!newValue || newValue instanceof DefaultValue) return;
      if (newValue.length === 0 || newValue === undefined) {
        set(
          unitsOverviewFilterAtom,

          (prev) => ({ ...prev, [filterType]: [] })
        );
      }
      if (newValue.length > 0) {
        set(unitsOverviewFilterAtom, (prev) => ({ ...prev, [filterType]: newValue as string[] }));
      }
    },
});
export const sortSelectorFamily = selectorFamily<string, keyof SortingDropDownProps>({
  key: "sortSelectorFamily",
  get:
    (filterType) =>
    ({ get }) => {
      const filterValues = get(unitsOverviewFilterAtom);
      return filterValues[filterType] ?? "";
    },

  set:
    (filterType) =>
    ({ set }, newValue) => {
      if (newValue === "default") {
        set(
          unitsOverviewFilterAtom,

          (prev) => ({ ...prev, [filterType]: "" })
        );
      }
      if (newValue || newValue === "") {
        set(unitsOverviewFilterAtom, (prev) => ({
          ...prev,
          [filterType]: newValue as string,
        }));
      }
    },
});

export const queryFilterSelectorFamily = selectorFamily<string[], keyof QueryMultiSelectProps>({
  key: "queryFilterSelectorFamily",
  get:
    (filterType) =>
    ({ get }) => {
      const filterValues = get(unitsOverviewFilterAtom);
      return filterValues[filterType] ?? [];
    },

  set:
    (filterType) =>
    ({ set }, newValue) => {
      if (!newValue || newValue instanceof DefaultValue) return;
      if (newValue.length === 0 || newValue === undefined) {
        set(
          unitsOverviewFilterAtom,

          (prev) => ({ ...prev, [filterType]: [] })
        );
      }
      if (newValue.length > 0) {
        set(unitsOverviewFilterAtom, (prev) => ({ ...prev, [filterType]: newValue as string[] }));
      }
    },
});
export const rangeSliderFilterSelectorFamily = selectorFamily<number[], keyof RangeSliderProps>({
  key: "rangeSliderFilterSelectorFamily",
  get:
    (filterType) =>
    ({ get }) => {
      const filterValues = get(unitsOverviewFilterAtom);
      return filterValues[filterType] ?? [];
    },

  set:
    (filterType) =>
    ({ set }, newValue) => {
      if (!newValue || newValue instanceof DefaultValue) return;
      if (newValue.length === 0 || newValue === undefined) {
        set(
          unitsOverviewFilterAtom,

          (prev) => ({ ...prev, [filterType]: [] })
        );
      }
      if (newValue.length > 0) {
        set(unitsOverviewFilterAtom, (prev) => ({ ...prev, [filterType]: newValue as number[] }));
      }
    },
});
export const toggleFilterSelectorFamily = selectorFamily<boolean, keyof ToggleFiltersProps>({
  key: "toggleSliderFilterSelectorFamily",
  get:
    (filterType) =>
    ({ get }) => {
      const filterValues = get(unitsOverviewFilterAtom);
      return filterValues[filterType] ?? [];
    },

  set:
    (filterType) =>
    ({ set }, newValue) => {
      if (newValue instanceof DefaultValue) return;
      if (!newValue) {
        set(
          unitsOverviewFilterAtom,

          (prev) => ({ ...prev, [filterType]: false })
        );
      }
      if (newValue === true) {
        set(unitsOverviewFilterAtom, (prev) => ({ ...prev, [filterType]: newValue }));
      }
    },
});
export const toggleUploadedByFilterSelectorFamily = selectorFamily<string, keyof ToggleStringFiltersProps>({
  key: "toggleUploadedByFilterSelectorFamily",
  get:
    (filterType) =>
    ({ get }) => {
      const filterValues = get(unitsOverviewFilterAtom);
      return filterValues[filterType] ?? "";
    },

  set:
    (filterType) =>
    ({ set }, newValue) => {
      if (newValue instanceof DefaultValue) return;
      if (!newValue) {
        set(
          unitsOverviewFilterAtom,

          (prev) => ({ ...prev, [filterType]: "" })
        );
      }
      if (newValue !== "") {
        set(unitsOverviewFilterAtom, (prev) => ({ ...prev, [filterType]: newValue }));
      }
    },
});
