import { Box } from "@mui/system";
import ResetFilterButton from "components/molecules/ResetFilterButton";
import { useGetUserInfo } from "lib/userInfo";
import React from "react";
import { useResetRecoilState } from "recoil";
import ordinaryColors from "themes/ordinaryColors";
import translations from "translations";
import { unitsOverviewFilterAtom } from "../State/manageFiltersInUnitsOverview";
import useCheckIfFiltersAreApplied from "../utils/useCheckIfFiltersAreApplied";
import BooleanToggleFilters from "./BooleanToggleFilters";
import FilterForAutoCompleteWithMultiselect from "./FilterForAutoCompleteWithMultiselect";
import FilterMultiselectForQueries from "./FilterMultiselectForQueries/index";
import MultiSelectFilterForEnums from "./MultiSelectFilterForEnums";
import RangeSliderFilter from "./RangeSliderFilter";
import SortingDropDown from "./SortingDropDown";
import SubscribedFilter from "./SubscribedFilter";
import ToggleOwnCertificatesAndSubscribed from "./ToggleOwnCertificatesAndSubscribed";

interface Props {}

const FilterBar: React.FC<Props> = () => {
  const resetAllFilters = useResetRecoilState(unitsOverviewFilterAtom);

  const userInfo = useGetUserInfo();
  const userId = userInfo?.id;
  const { filtersIsTrue } = useCheckIfFiltersAreApplied();

  return (
    <Box
      sx={{
        display: "flex",
        width: "100%",
        justifyContent: "space-between",
        alignItems: "center",
        backgroundColor: ordinaryColors.functionalLightGreyBack,
        padding: " 16px 0",
      }}
    >
      <Box>
        <BooleanToggleFilters
          filterType="hasZerezId"
          title={translations.pages.certificationManagement.filterTitles.zerezId}
        />
        <FilterMultiselectForQueries
          filterType="certificateNorms"
          title={translations.pages.certificationManagement.filterTitles.norm}
        />
        <MultiSelectFilterForEnums
          filterType="primaryEnergySource"
          title={translations.pages.certificationManagement.filterTitles.primaryEnergy}
        />
        <MultiSelectFilterForEnums
          filterType="categoryClassEquipment"
          title={translations.entities.unit.unitTypeName}
        />
        {/* <RangeSliderFilter
          filterType="ratedCurrent"
          title={translations.pages.certificationManagement.filterTitles.ratedPower}
        /> */}
        <RangeSliderFilter
          filterType="maxActivePower"
          title={translations.pages.certificationManagement.filterTitles.maxActivePower}
        />
        <RangeSliderFilter
          filterType="ratedVoltage"
          title={translations.pages.certificationManagement.filterTitles.ratedVoltage}
        />
        <FilterMultiselectForQueries
          filterType="documentType"
          title={translations.pages.certificationManagement.filterTitles.certificateType}
        />
        <FilterMultiselectForQueries
          filterType="certificateValidityStatus"
          title={translations.pages.certificationManagement.filterTitles.certificateStatus}
        />
        <FilterForAutoCompleteWithMultiselect
          filterType="certificateHolderId"
          title={translations.pages.certificationManagement.filterTitles.manufacturer}
          width={200}
        />
        <FilterForAutoCompleteWithMultiselect
          filterType="certificateAuthorityId"
          title={translations.pages.certificationManagement.filterTitles.authority}
          width={250}
        />
        {userId && (
          <>
            <ToggleOwnCertificatesAndSubscribed
              filterType="tenantId"
              title={translations.pages.certificationManagement.filterTitles.ownCertificates}
            />
          </>
        )}
        <BooleanToggleFilters
          filterType="isVerified"
          title={translations.pages.certificationManagement.filterTitles.verified}
        />
        {userId && (
          <>
            <SubscribedFilter
              userId={userId}
              title={translations.pages.certificationManagement.filterTitles.subscribed}
            />
          </>
        )}
        {userId && (
          <BooleanToggleFilters
            filterType="isNotReplaced"
            title={translations.pages.certificationManagement.filterTitles.isNotReplaced}
          />
        )}
        <SortingDropDown
          filterType="createdAt"
          title={translations.pages.certificationManagement.filterTitles.sortByCreatedAt}
        />
        <SortingDropDown
          filterType="modifiedAt"
          title={translations.pages.certificationManagement.filterTitles.sortByModifiedAt}
        />
      </Box>
      <Box sx={{ width: "200px" }}>
        {filtersIsTrue && (
          <ResetFilterButton title={translations.globals.button.resetAllFilters} resetFilters={resetAllFilters} />
        )}
      </Box>
    </Box>
  );
};

export default FilterBar;
