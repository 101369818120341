import React from "react";
import switchStatus from "./switchStatus";
type StatusCellProps = {
  status: string | undefined;
  replacedByCertificate?: { certificateNumber: string; id: string };
};
const StatusCell: React.FC<StatusCellProps> = ({ status, replacedByCertificate }) => {
  const showSwitchStatus = switchStatus({
    status: status || "",
    replacedByCertificate,
  });
  return <>{showSwitchStatus}</>;
};
export default StatusCell;
