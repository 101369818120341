import { FormHelperText } from "@mui/material";
import { Box } from "@mui/system";
import { unitFormsWithErrorsSelector } from "pages/UploadCertificate/state/uploadCertificateUnitState";
import * as React from "react";
import { useRecoilValue } from "recoil";
import ordinaryColors from "themes/ordinaryColors";
import Props from "./index.d";

const UnitErrorsOnCertificate: React.FC<Props> = (props) => {
  const unitErrors = useRecoilValue(unitFormsWithErrorsSelector);

  const {
    className,
    errorManager,
    data: { setAndSave },
  } = props;

  const errorManagerRes = errorManager({
    errorJoiner: (errors) => {
      return errors.join(", ");
    },
  });

  React.useEffect(() => {
    const unitsErrorsSum = unitErrors.reduce<number>((acc, unit) => {
      return acc + unit.count;
    }, 0);

    setAndSave(unitsErrorsSum);
  }, [unitErrors, setAndSave]);
  if (errorManagerRes.error)
    return (
      <Box sx={{ height: `calc(55px * ${unitErrors.length})` }}>
        <FormHelperText
          className={className}
          sx={{ color: ordinaryColors.error, marginLeft: "0px", fontSize: "16px", margin: "2rem 0 0.5rem" }}
        >
          {errorManagerRes.helperText}
        </FormHelperText>
      </Box>
    );
  return null;
};

export default UnitErrorsOnCertificate;
