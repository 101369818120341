import { Box } from "@mui/material";
import StatusTag from "components/molecules/StatusTag/StatusTag";
import useDataGrid from "components/templates/dataGridTable";
import { format } from "date-fns";
import { useGetCertificateHistoriesQuery } from "graphqlBase/certificateHistory/__generated__/getCertificateHistories";
import {
  CertificateInfoForRecursionFragment,
  GetCertificateBridgeUnitsQuery,
  useGetCertificateBridgeUnitsLazyQuery,
} from "graphqlBase/getCertificateBridgeUnits/__generated__/getCertificateBridgeUnits";
import { PaginatedResultType, Unit } from "graphqlBase/types";
import { getTranslatedCertificateTypeString } from "lib/helper/getTranslatedRowDataStrings";
import { useGetUserInfo } from "lib/userInfo";
import ordinaryColors from "themes/ordinaryColors";
import translations from "translations";
import useGetTenantNameById from "utils/useGetTenantNameById";
import { MigrationNoteTooltip } from "./MigrationNoteTooltip";
import StatusCell from "./StatusColumn/StatusCell";
import UnitsAndCertificatesLink, { getCertificateLinkInput } from "./UnitsAndCertificatesLink";

type CertificateBridgeUnit = PaginatedResultType<
  GetCertificateBridgeUnitsQuery["certificateBridgeUnits"]
>["certificate"];
export type CertificateOnUnit = CertificateInfoForRecursionFragment;
type ReplacementHistoryCertificate = CertificateOnUnit & {
  replacedByCertificate?: ReplacementHistoryCertificate | null;
  replacedCertificates?: ReplacementHistoryCertificate[] | null;
};

interface DetailPanelContentProps {
  hasActiveErrorReport?: boolean;
  isPlatformOperator?: boolean;
  row: Unit;
  styleForDetailView?: boolean;
}

const flattenCertificates = (
  certificate: ReplacementHistoryCertificate | CertificateBridgeUnit
): CertificateOnUnit[] => {
  const flattenedCertificates: CertificateOnUnit[] = [certificate];
  const replacedByCertificate = certificate.replacedByCertificate;
  const replacedCertificates = certificate.replacedCertificates;

  if (replacedByCertificate) {
    flattenedCertificates.push(...flattenCertificates(replacedByCertificate));
  }

  if (replacedCertificates) {
    replacedCertificates.forEach((replacedCertificate) => {
      if (replacedCertificate) flattenedCertificates.push(...flattenCertificates(replacedCertificate));
    });
  }

  return flattenedCertificates.sort((a, b) => (b.revision ?? 0) - (a.revision ?? 0));
};

const DetailPanelContent = ({
  row: rowProp,
  isPlatformOperator,
  styleForDetailView,
  hasActiveErrorReport,
}: DetailPanelContentProps) => {
  const { tenantNameById } = useGetTenantNameById({ withDeniedTenants: true });
  const userInfo = useGetUserInfo();
  const isUserPlatformOperator = userInfo?.role === "PLATFORM_OPERATOR";
  const [query, { data, error, loading }] = useGetCertificateBridgeUnitsLazyQuery();
  const { data: certificateHistory } = useGetCertificateHistoriesQuery({
    variables: {
      where: {
        and: [
          { certificateId: { eq: data?.certificateBridgeUnits?.items?.[0]?.certificate?.id } },
          { inEditByTenantId: { neq: null } },
        ],
      },
      order: [{ modifiedAt: "DESC" }],
      take: 1,
    },
    fetchPolicy: "network-only",
    skip: !data,
  });
  const { DataGrid, useMakeColumns, apiRef } = useDataGrid<CertificateOnUnit, "CertificateBridgeUnit">({
    variables: {
      where: {
        unitId: { eq: rowProp.id },
      },
    },
    query,
    tableId: "DetailPanel",
    // persistance: "runTime",
  });
  const minWidthColumn = 150;
  const isImported = data?.certificateBridgeUnits?.items?.[0]?.certificate?.isImported;
  const modifiedAt = certificateHistory?.certificateHistories?.items?.[0]?.modifiedAt;
  const isModified = !!modifiedAt;
  const modifiedBy = certificateHistory?.certificateHistories?.items?.[0]?.inEditByTenantId;
  const columns = useMakeColumns([
    {
      field: "CertificateNumber",
      headerName: translations.entities.certificate.certificateNumber,
      type: "string",
      flex: 2,
      minWidth: minWidthColumn,
      renderCell: ({ row }) => {
        return (
          <Box sx={{ display: "flex", flexDirection: "row", gap: "8px" }}>
            <span
              style={{
                overflow: "hidden",
                textOverflow: "ellipsis",
                marginTop: "-3px",
                wordBreak: "break-word",
              }}
            >
              {UnitsAndCertificatesLink(getCertificateLinkInput({ row, hasActiveErrorReport, isPlatformOperator }))}
            </span>
            {row.isImported && <MigrationNoteTooltip entity="certificate" />}
          </Box>
        );
      },
    },
    {
      field: "DocumentType",
      headerName: translations.entities.certificate.certificateType,
      type: "string",
      flex: 2,
      width: minWidthColumn,
      valueGetter: (params) => getTranslatedCertificateTypeString(params.row?.certificateType?.id),
    },
    {
      field: "CertificateCode",
      headerName: translations.entities.certificate.certificateCode,
      type: "string",
      flex: 2,
      minWidth: minWidthColumn,
      renderCell: ({ row }) => {
        return (
          <span style={{ overflow: "hidden", textOverflow: "ellipsis" }}>
            {(row.isImported && !row.isVerified) || row.withTG8Rev25Conformity
              ? translations.globals.helpText.notAssigned
              : row.certificateCode}
          </span>
        );
      },
    },
    // {
    //   field: "Validity",
    //   headerName: translations.entities.certificate.certificateValidity,
    //   type: "string",
    //   flex: 2,
    //   minWidth: minWidthColumn,
    //   valueGetter: ({ row }) => {
    //     const certificate = row;
    //     const certificateValidFrom = certificate?.validityStartDate;
    //     const certificateValidTo = certificate?.validityEndDate;
    //     const certificateBridgeCertificateNormIssueDate = certificate?.certificateBridgeCertificateNormIssueDates?.[0];
    //     const isNorm4105 = certificateBridgeCertificateNormIssueDate
    //       ? certificateBridgeCertificateNormIssueDate.certificateNormIssueDate?.certificateNormBridges?.[0].certificateNorm?.certificateNormName?.includes(
    //           "4105"
    //         )
    //       : false;
    //     return certificate
    //       ? certificateValidTo && certificateValidFrom
    //         ? certificateValidFrom === certificateValidTo && isNorm4105
    //           ? `${translations.pages.certificationManagement.since} ${formatDate(new Date(certificateValidFrom))}`
    //           : `${formatDate(new Date(certificateValidFrom))} - ${formatDate(new Date(certificateValidTo))}`
    //         : certificate.certificateIssueDate
    //         ? formatDate(new Date(certificate?.certificateIssueDate))
    //         : ""
    //       : "";
    //   },
    // },
    {
      field: "Certification Body",
      headerName: translations.entities.certificate.certifyingAuthority,
      type: "string",
      flex: 2,
      minWidth: minWidthColumn,
      valueGetter: ({ row }) => (row.certificateAuthorityId ? tenantNameById[row.certificateAuthorityId] : ""),
    },

    {
      field: "Status",
      headerName: translations.pages.certificationManagement.certificateStatus,
      flex: 3,
      minWidth: minWidthColumn,
      renderCell: ({ row }) => {
        const certificate = row;
        const status = certificate?.certificateValidityStatus?.certificateValidityStatusName;
        const replacedByCertificateId = certificate?.getReplacedByCertificate?.id; // have to use alias here because replacedByCertificate is a recursive field
        const replacedByCertificateNumber = certificate?.getReplacedByCertificate?.certificateNumber;

        return (
          <StatusCell
            status={status}
            replacedByCertificate={{
              certificateNumber: replacedByCertificateNumber ?? "",
              id: replacedByCertificateId ?? "",
            }}
          />
        );
      },
      type: "dateTime",
    },
    {
      field: "createdAt",
      headerName: isImported
        ? translations.entities.role.columns.importedAt
        : translations.entities.role.columns.createdAt,
      flex: 2,
      minWidth: minWidthColumn,
      valueGetter: ({ row }) =>
        row.createdAt ? format(new Date(row.transformedOn ?? row.createdAt), "dd.MM.yyyy HH:mm") : "",
      type: "dateTime",
    },
    {
      field: "createdBy",
      headerName: isImported
        ? translations.entities.role.columns.importedBy
        : translations.entities.role.columns.createdBy,
      flex: 2,
      minWidth: minWidthColumn,
      valueGetter: ({ row }) =>
        row.transformedBy ? tenantNameById[row.transformedBy] : row.tenantId ? tenantNameById[row.tenantId] : "",
      type: "string",
    },
    {
      field: "modifiedAt",
      headerName: translations.entities.certificate.modifiedAt,
      flex: 2,
      minWidth: minWidthColumn,
      valueGetter: ({ row }) => (modifiedAt ? format(new Date(modifiedAt), "dd.MM.yyyy HH:mm") : ""),
      type: "dateTime",
    },
    {
      field: "modifiedBy",
      headerName: translations.entities.role.columns.modifiedBy,
      flex: 2,
      minWidth: minWidthColumn,
      valueGetter: () => modifiedBy && tenantNameById[modifiedBy],
      type: "string",
    },
    {
      field: "Certificate Check",
      headerName: translations.pages.certificationManagement.certificateCheck,
      flex: 3,
      minWidth: minWidthColumn,
      renderCell: ({ row }) => {
        const inCorrection = row.inEditByTenantId;
        const isValidityStatusVoid = row.certificateValidityStatus?.certificateValidityStatusName === "Void";
        if (isValidityStatusVoid) {
          return null;
        }
        if (inCorrection) {
          return (
            <StatusTag
              title={translations.pages.certificationManagement.inCorrection}
              color={ordinaryColors.warningDark}
            />
          );
        }
        return row.isVerified ? (
          <StatusTag
            title={translations.pages.certificationManagement.verified}
            backgroundColor={ordinaryColors.sucess200}
            color={ordinaryColors.grey900}
            borderColor="transparent"
          />
        ) : (
          // <StatusTag title={translations.pages.certificationManagement.notVerified} color={ordinaryColors.errorMain} />
          <StatusTag
            title={translations.pages.certificationManagement.verificationPending}
            color={ordinaryColors.grey600}
          />
        );
      },
      type: "string",
    },
  ]);

  const certificatesFlat = (data?.certificateBridgeUnits?.items ?? []).flatMap((item) =>
    flattenCertificates(item.certificate).sort((a, b) => {
      if (a.isImported === b.isImported) {
        return (b.revision ?? 0) - (a.revision ?? 0);
      }
      return a.isImported ? 1 : -1;
    })
  );

  return (
    <Box
      sx={{
        display: styleForDetailView ? "flex" : "unset",
        justifyContent: styleForDetailView ? "center" : "unset",
        boxSizing: "border-box",
        width: "100%",
        background: styleForDetailView ? "none" : ordinaryColors.functionalGreyBack,
      }}
    >
      <Box sx={{ flex: 1, mx: "auto", width: "90%", p: styleForDetailView ? 0 : 1 }}>
        <Box
          sx={{
            height: 1,
            background: styleForDetailView ? "none" : ordinaryColors.functionalGreyBack,
            display: styleForDetailView ? "flex" : "unset",
            justifyContent: styleForDetailView ? "center" : "unset",
          }}
        >
          <DataGrid
            sx={{
              background: styleForDetailView ? ordinaryColors.functionalWhite : ordinaryColors.functionalGreyBack,
              "& .MuiDataGrid-cell": {
                alignItems: "start",
                diplay: "flex",
              },
            }}
            hasToolbar={false}
            autoHeight
            disableSelectionOnClick
            loading={loading}
            error={error}
            columns={columns}
            rows={certificatesFlat}
            noDataMessage={translations.globals.notifications.noDataAvailable}
            tableHeight="65vh"
            tableTitle="Assets"
            apiRef={apiRef}
            totalCount={data?.certificateBridgeUnits?.totalCount}
            checkboxSelection={false}
            getRowHeight={() => "auto"}
            hideFooterPagination
            hideFooter
            columnVisibilityModel={{
              createdBy: isUserPlatformOperator,
              modifiedAt: isModified && !!modifiedAt,
              modifiedBy: isModified && isUserPlatformOperator,
            }}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default DetailPanelContent;
