import { getManufacturerAndUnitsInfo } from "pages/UploadCertificate/utils";
import { UnitCreateRules } from "raft/UnitForm";
import translations from "translations";
import {
  categoriesInvalidOnNorm5,
  checkFractionalDigits,
  isCategoryWithLimitedValidation,
  isFieldRequied,
  isInteger,
  makeGenericUnitFieldRule,
  noValidationCertificateTypeIds,
  optionalField,
} from "../utils";

const genericRules = {
  // 1.19
  netFeedIn: makeGenericUnitFieldRule("netFeedIn"),
  // 1.21
  // maxActivePower09: makeGenericUnitFieldRule("maxActivePower09"),
  // 1.22
  // maxApparentPower: makeGenericUnitFieldRule("maxApparentPower"),
  // 1.25
  ratedVoltage: makeGenericUnitFieldRule("ratedVoltage", [isInteger]),
  // 1.26
  ratedCurrent: makeGenericUnitFieldRule("ratedCurrent"),
  // 1.27
  shortCircuitPeakCurrent: makeGenericUnitFieldRule("shortCircuitPeakCurrent"),
  // 1.28
  initialShortCircuitAlternatingCurrent: makeGenericUnitFieldRule("initialShortCircuitAlternatingCurrent"),
  // 1.29
  operation1MaxSwitchN10: makeGenericUnitFieldRule("operation1MaxSwitchN10", [isInteger]),
  // 1.30
  operation1MaxNumberN120: makeGenericUnitFieldRule("operation1MaxNumberN120", [isInteger]),
  // 1.31
  operation1VoltageVariationFactor30: makeGenericUnitFieldRule("operation1VoltageVariationFactor30"),
  // 1.32
  operation1VoltageVariationFactor50: makeGenericUnitFieldRule("operation1VoltageVariationFactor50"),
  // 1.33
  operation1VoltageVariationFactor70: makeGenericUnitFieldRule("operation1VoltageVariationFactor70"),
  // 1.34
  operation1VoltageVariationFactor85: makeGenericUnitFieldRule("operation1VoltageVariationFactor85"),
  // 1.35
  operation1FlickerStepFactor30: makeGenericUnitFieldRule("operation1FlickerStepFactor30"),
  // 1.36
  operation1FlickerStepFactor50: makeGenericUnitFieldRule("operation1FlickerStepFactor50"),
  // 1.37
  operation1FlickerStepFactor70: makeGenericUnitFieldRule("operation1FlickerStepFactor70"),
  // 1.38
  operation1FlickerStepFactor85: makeGenericUnitFieldRule("operation1FlickerStepFactor85"),
  // 1.39
  operation2MaxSwitchN10: makeGenericUnitFieldRule("operation2MaxSwitchN10"),
  // 1.40
  operation2MaxNumberN120: makeGenericUnitFieldRule("operation2MaxNumberN120"),
  // 1.41
  operation2VoltageVariationFactor30: makeGenericUnitFieldRule("operation2VoltageVariationFactor30"),
  // 1.42
  operation2VoltageVariationFactor50: makeGenericUnitFieldRule("operation2VoltageVariationFactor50"),
  // 1.43
  operation2VoltageVariationFactor70: makeGenericUnitFieldRule("operation2VoltageVariationFactor70"),
  // 1.44
  operation2VoltageVariationFactor85: makeGenericUnitFieldRule("operation2VoltageVariationFactor85"),
  // 1.45
  operation2FlickerStepFactor30: makeGenericUnitFieldRule("operation2FlickerStepFactor30"),
  // 1.46
  operation2FlickerStepFactor50: makeGenericUnitFieldRule("operation2FlickerStepFactor50"),
  // 1.47
  operation2FlickerStepFactor70: makeGenericUnitFieldRule("operation2FlickerStepFactor70"),
  // 1.48
  operation2FlickerStepFactor85: makeGenericUnitFieldRule("operation2FlickerStepFactor85"),
  // 1.49
  operation3MaxSwitchN10: makeGenericUnitFieldRule("operation3MaxSwitchN10"),
  // 1.50
  operation3MaxNumberN120: makeGenericUnitFieldRule("operation3MaxNumberN120"),
  // 1.51
  operation3VoltageVariationFactor30: makeGenericUnitFieldRule("operation3VoltageVariationFactor30"),
  // 1.52
  operation3VoltageVariationFactor50: makeGenericUnitFieldRule("operation3VoltageVariationFactor50"),
  // 1.53
  operation3VoltageVariationFactor70: makeGenericUnitFieldRule("operation3VoltageVariationFactor70"),
  // 1.54
  operation3VoltageVariationFactor85: makeGenericUnitFieldRule("operation3VoltageVariationFactor85"),
  // 1.55
  operation3FlickerStepFactor30: makeGenericUnitFieldRule("operation3FlickerStepFactor30"),
  // 1.56
  operation3FlickerStepFactor50: makeGenericUnitFieldRule("operation3FlickerStepFactor50"),
  // 1.57
  operation3FlickerStepFactor70: makeGenericUnitFieldRule("operation3FlickerStepFactor70"),
  // 1.58
  operation3FlickerStepFactor85: makeGenericUnitFieldRule("operation3FlickerStepFactor85"),
  // 1.59
  fastVoltageChangeTurnOnWithoutSpecifications: makeGenericUnitFieldRule(
    "fastVoltageChangeTurnOnWithoutSpecifications"
  ),
  // 1.60
  fastVoltageChangeWorstCaseDuringGeneratorStageSwitching: makeGenericUnitFieldRule(
    "fastVoltageChangeWorstCaseDuringGeneratorStageSwitching"
  ),
  // 1.61
  fastVoltageChangeTurnOnAtRatedConditions: makeGenericUnitFieldRule("fastVoltageChangeTurnOnAtRatedConditions"),
  // 1.62
  fastVoltageChangeTurnOffAtRatedPower: makeGenericUnitFieldRule("fastVoltageChangeTurnOffAtRatedPower"),
  // 1.63
  operationAllWorstCase: makeGenericUnitFieldRule("operationAllWorstCase"),
  // 1.64
  systemFlickerCoefficientCY30: makeGenericUnitFieldRule("systemFlickerCoefficientCY30"),
  // 1.65
  systemFlickerCoefficientCY50: makeGenericUnitFieldRule("systemFlickerCoefficientCY50"),
  // 1.66
  systemFlickerCoefficientCY70: makeGenericUnitFieldRule("systemFlickerCoefficientCY70"),
  // 1.67
  systemFlickerCoefficientCY85: makeGenericUnitFieldRule("systemFlickerCoefficientCY85"),
};

const uniFormValidationRules: UnitCreateRules = {
  unitTypeId: [optionalField],
  // 1.03
  manufacturerId: [optionalField],
  // 1.04 --> always required
  modelName: [
    async ({ value, data }) => {
      if (!value && data.__certificateTypeId) return translations.globals.validationMessages.required;
      if (data.__isLinked) return undefined;
      const { manufacturerId, allUnitNames } = await getManufacturerAndUnitsInfo();
      if (!value || !manufacturerId) return undefined;
      if (allUnitNames.filter((v) => v === value).length > 1)
        return translations.pages.certificateUpload.errorMessages.modelAlreadyExists;
    },
  ],
  // 1.04 (?)
  modelNumber: [optionalField],
  // 1.20
  maxActivePower: [
    {
      rule: ({ value, data }) => {
        if (
          !data.__withTG8Rev25Conformity &&
          data.__certificateTypeId &&
          !noValidationCertificateTypeIds.includes(data.__certificateTypeId) && // only validate "maxActivePower" for verification document of type certificate
          data.__category &&
          !isCategoryWithLimitedValidation(data.__category) &&
          !data.hasActivePowerRange &&
          !value &&
          value !== 0
        )
          return translations.globals.validationMessages.required;
        return undefined;
      },
      triggerFields: ["hasActivePowerRange", "__certificateTypeId", "__withTG8Rev25Conformity"],
      liveValidation: true,
    },
    {
      rule: checkFractionalDigits("maxActivePower"),
    },
  ],
  // 1.20
  minActivePowerRange: [
    {
      rule: ({ value, data }) => {
        if (
          !data.__withTG8Rev25Conformity &&
          data.__certificateTypeId &&
          !noValidationCertificateTypeIds.includes(data.__certificateTypeId) && // only validate "minActivePowerRange" for verification document of type certificate
          data.__category &&
          !isCategoryWithLimitedValidation(data.__category) &&
          data.hasActivePowerRange &&
          !value &&
          value !== 0
        )
          return translations.globals.validationMessages.required;
        return undefined;
      },
      triggerFields: ["hasActivePowerRange"],
    },
    {
      rule: ({ value, data }) => {
        if (data.maxActivePowerRange && value && value > data.maxActivePowerRange)
          return translations.globals.validationMessages.minHigherMax;
        return undefined;
      },
      triggerFields: ["maxActivePowerRange"],
    },
    {
      rule: checkFractionalDigits("minActivePowerRange"),
    },
  ],
  // 1.20
  maxActivePowerRange: [
    {
      rule: ({ value, data }) => {
        if (data.minActivePowerRange && value && value < data.minActivePowerRange)
          return translations.globals.validationMessages.maxLowerMin;
        return undefined;
      },
      triggerFields: ["minActivePowerRange"],
    },
    {
      rule: checkFractionalDigits("maxActivePowerRange"),
    },
  ],
  // 1.21
  maxActivePower09: [
    {
      rule: ({ value, data }) => {
        if (!data.__category || categoriesInvalidOnNorm5.includes(data.__category)) return undefined;
        return isFieldRequied("maxActivePower09")({ value, data });
      },
      triggerFields: ["__category"],
      makeOptional: true,
    },
    {
      rule: checkFractionalDigits("maxActivePower09"),
    },
  ],
  // 1.22
  maxApparentPower: [
    {
      rule: ({ value, data }) => {
        if (
          !data.__withTG8Rev25Conformity &&
          data.__certificateTypeId &&
          !noValidationCertificateTypeIds.includes(data.__certificateTypeId) && // only validate "maxApparentPower" for verification document of type certificate
          data.__category &&
          !isCategoryWithLimitedValidation(data.__category) &&
          !data.hasApparentPowerRange &&
          !value &&
          value !== 0
        )
          return translations.globals.validationMessages.required;
        return undefined;
      },
      triggerFields: ["hasApparentPowerRange"],
    },
    {
      rule: checkFractionalDigits("maxApparentPower"),
    },
  ],
  // 1.22
  minApparentPowerRange: [
    {
      rule: ({ value, data }) => {
        if (
          !data.__withTG8Rev25Conformity &&
          data.__certificateTypeId &&
          !noValidationCertificateTypeIds.includes(data.__certificateTypeId) && // only validate "minApparentPowerRange" for verification document of type certificate
          data.__category &&
          !isCategoryWithLimitedValidation(data.__category) &&
          data.hasApparentPowerRange &&
          !value &&
          value !== 0
        )
          return translations.globals.validationMessages.required;
        return undefined;
      },
      triggerFields: ["hasApparentPowerRange"],
    },
    {
      rule: ({ value, data }) => {
        if (data.maxApparentPowerRange && value && value > data.maxApparentPowerRange)
          return translations.globals.validationMessages.minHigherMax;
        return undefined;
      },
      triggerFields: ["maxApparentPowerRange"],
    },
    {
      rule: checkFractionalDigits("minApparentPowerRange"),
    },
  ],
  // 1.22
  maxApparentPowerRange: [
    {
      rule: ({ value, data }) => {
        if (data.minApparentPowerRange && value && value < data.minApparentPowerRange)
          return translations.globals.validationMessages.maxLowerMin;
        return undefined;
      },
      triggerFields: ["maxApparentPowerRange"],
    },
    {
      rule: checkFractionalDigits("maxApparentPowerRange"),
    },
  ],
  ratedActivePower: [
    {
      rule: isFieldRequied("ratedActivePower"),
      makeOptional: true,
    },
    {
      rule: ({ value, data }) => {
        if (
          data.__certificateTypeId &&
          !noValidationCertificateTypeIds.includes(data.__certificateTypeId) && // only validate "minApparentPowerRange" for verification document of type certificate
          data.__category &&
          !isCategoryWithLimitedValidation(data.__category) &&
          data.__category !== "VOLTAGE_REGULATOR" && // category is not voltage regulator
          !value &&
          data.__withTG8Rev25Conformity &&
          !data.ratedApparentPower
        )
          return translations.pages.certificateUpload.errorMessages.withTG8Rev25ConformityRequired;
        return undefined;
      },
      triggerFields: ["__withTG8Rev25Conformity", "ratedApparentPower"],
    },
    {
      rule: checkFractionalDigits("ratedActivePower"),
    },
  ],
  ratedApparentPower: [
    {
      rule: isFieldRequied("ratedApparentPower"),
      makeOptional: true,
    },
    {
      rule: async ({ value, data }) => {
        if (
          data.__certificateTypeId &&
          !noValidationCertificateTypeIds.includes(data.__certificateTypeId) && // only validate "minApparentPowerRange" for verification document of type certificate
          data.__category &&
          !isCategoryWithLimitedValidation(data.__category) &&
          data.__category !== "VOLTAGE_REGULATOR" && // category is not voltage regulator
          !value &&
          data.__withTG8Rev25Conformity &&
          !data.ratedActivePower
        )
          return translations.pages.certificateUpload.errorMessages.withTG8Rev25ConformityRequired;
        return undefined;
      },
      triggerFields: ["__withTG8Rev25Conformity", "ratedActivePower"],
    },
    {
      rule: checkFractionalDigits("ratedApparentPower"),
    },
  ],
  ...genericRules,
};

export default uniFormValidationRules;
