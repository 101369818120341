import { UnitCreateOnUpdateDataMiddleWareProps } from "raft/UnitForm";

const updatePowerRangesFieldBehaviour = (data: UnitCreateOnUpdateDataMiddleWareProps["data"]) => {
  if (data.hasActivePowerRange && typeof data.maxActivePower === "number") {
    data = {
      ...data,
      minActivePowerRange: data.maxActivePower,
      maxActivePower: null,
    };
  }

  if (!data.hasActivePowerRange && typeof data.minActivePowerRange === "number") {
    data = {
      ...data,
      maxActivePower: data.minActivePowerRange,
      minActivePowerRange: null,
    };
  }

  if (data.hasApparentPowerRange && typeof data.maxApparentPower === "number") {
    data = {
      ...data,
      minApparentPowerRange: data.maxApparentPower,
      maxApparentPower: null,
    };
  }

  if (!data.hasApparentPowerRange && typeof data.minApparentPowerRange === "number") {
    data = {
      ...data,
      maxApparentPower: data.minApparentPowerRange,
      minApparentPowerRange: null,
    };
  }

  return data;
};

export default updatePowerRangesFieldBehaviour;
