import { InputAdornment, InputProps, styled, TextField } from "@mui/material";
import Typography from "@mui/material/Typography";
import { DefaultInputProps } from "components/atomics/DefaultInput";
import React, { ChangeEventHandler, useState, useMemo, useCallback } from "react";
import ordinaryColors from "themes/ordinaryColors";
import translations from "translations";
import Props from "./index.d";

// eslint-disable-next-line @typescript-eslint/no-unused-vars
interface DecimalEditorPropsInner {
  inputProps?: InputProps;
}
export const DefaultInputSC = styled(TextField)<DefaultInputProps & { error?: boolean }>(
  ({ theme, isUnit, error }) => ({
    width: "100%",
    "& .MuiOutlinedInput-root": {
      background: error ? "rgba(211, 47, 47, 0.02)" : "inherit",
      borderRadius: isUnit ? 0 : "0.5rem",
      width: "100%",
      minWidth: isUnit ? "244px" : "inherit",
      maxWidth: isUnit ? "244px" : "inherit",
      "& .MuiOutlinedInput-inputTypeNumber": { "-webkit-appearance": "none" },
      "& input[type=number]": {
        "-moz-appearance": "textfield",
      },
      "& input[type=number]::-webkit-outer-spin-button": {
        "-webkit-appearance": "none",
        margin: 0,
      },
      "& input[type=number]::-webkit-inner-spin-button": {
        "-webkit-appearance": "none",
        margin: 0,
      },
    },
    "& .MuiOutlinedInput-input": {
      padding: "0.5rem 0.75rem",
      width: "100%",
      minWidth: isUnit ? "160px" : "inherit",
    },
    "& .MuiFormHelperText-root.Mui-error": {
      display: "none",
    },
  })
);
function useGetDecimalSeparator(locale: Intl.LocalesArgument) {
  const numberWithDecimalSeparator = 1.1;
  return useMemo(() => numberWithDecimalSeparator.toLocaleString(locale).substring(1, 2), [locale]);
}
const getTrailingChars = (value: string) => {
  const commaOrDot = ".";
  const trailinZeros = value
    .split("")
    .reverse()
    .reduce(
      ([trail, onnlyTrails], curr) => {
        if (value.includes(commaOrDot) && curr === "0" && onnlyTrails) {
          return [trail + "0", true] as [string, boolean];
        }
        if (curr === commaOrDot && onnlyTrails) {
          return [trail + curr, false] as [string, boolean];
        }
        return [trail, false] as [string, boolean];
      },
      ["", true] as [string, boolean]
    );

  return trailinZeros[0].split("").reverse().join("");
};

const DecimalEditorInner: React.FC<Props> = (props) => {
  const {
    data: { state, setstate, onBlur, setNull },
    title,
    testId,
    unit,
    className,
    validationRegex,
    errorManager,
    fractionLenght,
    wholeLenght,
    ...rest
  } = props;
  const UnitSymbol = <p style={{ color: ordinaryColors.functionalLightGrey }}>{unit}</p>;
  const [trailingChars, setTrailingChars] = useState("");
  const dotRegEx = `^[0-9]{1,${wholeLenght ?? ""}}(\\.[0-9]{0,${fractionLenght ?? ""}})?$`;
  const decimalSeparator = useGetDecimalSeparator(translations.getLanguage());
  const handleChange: ChangeEventHandler<HTMLTextAreaElement | HTMLInputElement> = useCallback(
    (e) => {
      e?.preventDefault();

      const currentRegex = validationRegex === undefined ? dotRegEx : validationRegex;
      const regex = new RegExp(currentRegex);
      const valuePreFloat = (e.target.value ?? "").replaceAll(decimalSeparator, ".");
      const valuePre = !!fractionLenght ? valuePreFloat : valuePreFloat.split(".")[0];
      const value = valuePre.startsWith(".") ? "0" + valuePre : valuePre;

      if (value === "" || regex.test(value) || !regex.test(`${state}`)) {
        const parsedValue = parseFloat(value);

        if (isNaN(parsedValue)) {
          if (setNull) setNull();

          return;
        }
        setTrailingChars(getTrailingChars(value));

        setstate(parsedValue);
      }
    },
    [validationRegex, dotRegEx, decimalSeparator, fractionLenght, state, setstate, setNull]
  );

  const errorManagerRes = props.errorManager({
    errorJoiner: (errors) => errors.join(", "),
  });
  const displayValue = `${state?.toString() ?? ""}${trailingChars}`.replaceAll(".", decimalSeparator);
  return (
    <DefaultInputSC
      disabled={props.disabled}
      sx={{
        height: props.small ? "40px" : "inherit",
        width: unit ? "235px" : "inherit",
        marginRight: rest.isUnit && unit ? "10px" : "0px",
      }}
      //testId={testId}
      onBlur={onBlur}
      value={displayValue}
      type="text"
      onChange={handleChange}
      InputProps={{
        endAdornment: <InputAdornment position="end">{UnitSymbol}</InputAdornment>,
        disableUnderline: props.disableUnderline,
      }}
      outlined={props.outlined}
      className={props.className}
      isUnit={props.isUnit}
      {...errorManagerRes}
    />
  );
};

const DecimalEditor: React.FC<Props> = (props) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { title, testId, isUnit } = props;

  return (
    <div className="className">
      <div>
        {!isUnit && title && (
          <Typography align="left" variant="inherit">
            {title}:
          </Typography>
        )}
      </div>
      <DecimalEditorInner {...props} />
      {!isUnit &&
        (props.errors ?? []).map((e, index) => (
          <div key={index}>
            <Typography key={index} align="left" variant="inherit">
              {e}
            </Typography>
          </div>
        ))}
    </div>
  );
};

export default DecimalEditor;
