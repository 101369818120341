export default {
  label: "My Profile",
  resetPassword: "Reset Password",
  notifcations: {
    notificationsTitle: "E-Mail Notifications Settings",
    notificationToggles: {
      label: {
        "fb9a3615-e273-5adb-a9de-b25920b76a60": "Upload of new Certificates",
        "93dfb205-6f3b-5ee0-9463-936231c2318d": "Rejected Certificates",
        "6e966a0f-a736-5e03-a298-dcf7e61e0435": "Corrected Certificates",
        "6bb90b10-23d2-5552-a26e-409b8108c857": "Validated Certificates",
        "41612901-7e48-5021-bde3-9b0f767366d1": "Raising Certifications Errors",
        "24a21cd6-34d8-585c-8b5b-e6c6675b80e6": "Error Rejections",
        "177c57ff-88a8-5a1c-8705-6f748b1d8578": "Error Resolvements",
        "e9a03d08-c6b8-5aa4-b3e4-92904a3d3bb5": "Error Acknowledgement",
        "96b84663-ad32-51eb-818e-b70a6400454e": "Certificate Expiration",
        "aac853cd-4806-5270-81ce-21e4a963ae47": "Access Request",
      },
      description: {
        "fb9a3615-e273-5adb-a9de-b25920b76a60":
          "Receive prompt notifications of newly uploaded certificates, ensuring quick approval or thorough double-checking.",
        "93dfb205-6f3b-5ee0-9463-936231c2318d":
          "Receive prompt notifications of rejections of certificate validations by counterparts",
        "6e966a0f-a736-5e03-a298-dcf7e61e0435":
          "Receive prompt notifications of certificates on which corrections were made as a result of previous rejection during validation",
        "6bb90b10-23d2-5552-a26e-409b8108c857":
          "Receive prompt notifications of successful validations of certificates by counterparts",
        "41612901-7e48-5021-bde3-9b0f767366d1":
          "Receive prompt notifications when concerns are raised about potential errors in certificate accuracy.",
        "24a21cd6-34d8-585c-8b5b-e6c6675b80e6":
          "Receive prompt notifications when concerns raised by you about potential errors in certificate data accuracy have been classified by the controlling party as not valid.",
        "177c57ff-88a8-5a1c-8705-6f748b1d8578":
          "Receive prompt notifications when concerns raised about a certificate for one of the units you're subscribing to have been resolved.",
        "e9a03d08-c6b8-5aa4-b3e4-92904a3d3bb5":
          "Receive prompt notifications when errors about a certificate for one of the units you're subscribing to have been acknowledged",
        "96b84663-ad32-51eb-818e-b70a6400454e":
          "Receive notifications when a certificate for one of the units you're subscribing to is about to expire",
        "aac853cd-4806-5270-81ce-21e4a963ae47":
          "Receive prompt notifications when your access request has been approved",
      },
    },

    // notificationToggles: {
    //   "fb9a3615-e273-5adb-a9de-b25920b76a60": {
    //     label: "Upload of new Certificates",
    //     description:
    //       "Receive a daily summary of newly uploaded certificates, ensuring quick approval or thorough double-checking.",
    //   },

    //   "93dfb205-6f3b-5ee0-9463-936231c2318d": {
    //     label: "Rejected Certificates",
    //     description: "Receive a daily summarizing rejections of certificate validations by counterparts",
    //   },

    //   "6e966a0f-a736-5e03-a298-dcf7e61e0435": {
    //     label: "Corrected Certificates",
    //     description:
    //       "Receive daily alerts summarizing certificates on which corrections were made as a result of previous rejection during validation",
    //   },

    //   "6bb90b10-23d2-5552-a26e-409b8108c857": {
    //     label: "Validated Certificates",
    //     description: "Receive daily alerts summarizing successful validations of certificates by counterparts",
    //   },

    //   "41612901-7e48-5021-bde3-9b0f767366d1": {
    //     label: "Raising Certifications Errors",
    //     description:
    //       "Receive prompt notifications when concerns are raised about potential errors in certificate accuracy. You`ll get a daily summary of any new issues raised.",
    //   },

    //   "24a21cd6-34d8-585c-8b5b-e6c6675b80e6": {
    //     label: "Error Rejections",
    //     description:
    //       "Receive prompt notifications when concerns raised by you about potential errors in certificate data accuracy have been classified by the controlling party as not valid. You will receive a daily summary of such cases.",
    //   },

    //   "e9a03d08-c6b8-5aa4-b3e4-92904a3d3bb5": {
    //     label: "Error Resolvments",
    //     description:
    //       "Receive prompt notifications when concerns raised about a certificate for one of the units you're subscribing to have been resolved. You will receive a daily summary of such cases.",
    //   },
    // },
  },
};
