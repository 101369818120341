import { Box, Button, styled, SvgIcon, Theme } from "@mui/material";
import ordinaryColors from "themes/ordinaryColors";

interface StyledUploadedDocsBoxProps {
  isHighlighted?: boolean;
}

export const ContainerSC = styled(Box, {
  label: "ContainerSC",
  shouldForwardProp(propName) {
    return propName !== "forTasks";
  },
})<{ forTasks?: boolean }>(({ theme, forTasks = false }) => ({
  display: "flex",
  width: forTasks ? "100%" : "unset",
  maxWidth: forTasks ? "100%" : "80%",
  padding: "48px",
  minWidth: "48rem",
  flexDirection: "column",
  margin: "auto",
  gap: "56px",
  alignSelf: "stretch",
  border: `1px solid ${ordinaryColors.functionalDivider}`,
  background: forTasks ? "none" : ordinaryColors.functionalWhite,
  marginTop: forTasks ? 0 : "32px",
  marginBottom: forTasks ? 0 : "2rem",
}));

export const HeaderSC = styled(Box, { label: "HeaderSC" })(({ theme }) => ({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "start",
  alignSelf: "stretch",
  flexDirection: "column",
  gap: "16px",
  width: "100%",
}));

export const HeaderTitleSC = styled(Box, { label: "HeaderTitleSC" })(({ theme }) => ({
  maxWidth: "100%",
  display: "flex",
  alignItems: "center",
  gap: "2rem",
  "@media (max-width: 1200px)": {
    flexDirection: "column",
    alignItems: "flex-start",
  },
}));

export const CertificateAndDocumentsInfoSC = styled(Box, { label: "CertificateAndDocumentsInfoSC" })(({ theme }) => ({
  display: "flex",
  alignItems: "flex-start",
  alignContent: "flex-start",
  gap: "32px",
  alignSelf: "stretch",
  "@media (max-width: 1200px)": {
    flexDirection: "column",
  },
}));

export const UploadedDocumentsContainerSC = styled(Box, { label: "UploadedDocumentsContainerSC" })(({ theme }) => ({
  display: "flex",
  width: "50%",
  flexDirection: "column",
  alignItems: "flex-start",
  gap: "24px",
}));

export const FileContainerSC = styled(Box, { label: "PDFXLContainerSC" })(({ theme }) => ({
  display: "flex",
  minWidth: "400px",
  maxWidth: "642px",
  flexDirection: "column",
  alignItems: "flex-start",
  gap: "4px",
  alignSelf: "stretch",
}));

export const FileTypeBoxSC = styled(Box, { label: "FileTypeBoxSC" })(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-start",
  gap: "2px",
}));

export const StyledDocumentContainer = styled(Box, { label: "StyledDocumentContainer" })(({ theme }) => ({
  display: "inline-flex",
  alignItems: "center",
  gap: "12px",
  height: "26px",
}));

export const StyledDocumentTypeName = styled(Box, {
  label: "StyledDocumentTypeName",
  shouldForwardProp(propName) {
    return propName !== "blocked";
  },
})(({ blocked = false }: { blocked?: boolean }) => ({
  color: !blocked ? ordinaryColors.primaryBrand : ordinaryColors.grey600,
  fontFeatureSettings: "'clig' off, 'liga' off",
  fontSize: "18px",
  fontStyle: "normal",
  fontWeight: 400,
  lineHeight: "143% /* 25.74px */",
  letterSpacing: "0.17px",
  whiteSpace: "nowrap",
}));

export const StyledFileName = styled(Box, { label: "StyledFileName" })(({ theme }) => ({
  width: "252px",
  color: ordinaryColors.lightTextPrimary,
  fontFeatureSettings: "'clig' off, 'liga' off",
  fontSize: "18px",
  fontStyle: "normal",
  fontWeight: 400,
  lineHeight: "143% /* 25.74px */",
  letterSpacing: "0.17px",
}));

export const StyledDownloadIconWrapper = styled(Box, { label: "StyledDownloadIconWrapper" })(({ theme }) => ({
  display: "flex",
  cursor: "pointer",
}));

// export const StyledDownloadButtonLabel = styled(Typography, { label: "StyledDownloadButtonLabel" })(({ theme }) => ({
//   color: ordinaryColors.primaryBrand,
//   fontFeatureSettings: "'clig' off, 'liga' off",
//   fontSize: "18px",
//   fontStyle: "normal",
//   fontWeight: 500,
//   lineHeight: "157% /* 28.26px */",
//   letterSpacing: "0.1px",
//   cursor: "pointer",
// }));

export const UnitsContainerSC = styled(Box, { label: "UnitsContainerSC" })(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-start",
  gap: "24px",
  alignSelf: "stretch",
}));
export const StyledBoxHeaderPart = styled(Box, { label: "StyledBoxHeaderPart" })({
  display: "flex",
  alignItems: "center",
  gap: "1rem",
  width: "100%",
});

export const StyledIconBoxHeaderPart = styled(Box, { label: "StyledIconBoxHeaderPart" })({
  display: "flex",
  gap: "1rem",
  flexShrink: 0,
});

export const StyledTitle = styled(Box, { label: "StyledTitle" })({
  display: "flex",
  color: ordinaryColors.functionalBlack,
  flex: "1 0 0",
  fontFeatureSettings: "'clig' off, 'liga' off",
  fontSize: "2.125rem",
  fontStyle: "normal",
  fontWeight: 400,
  lineHeight: "123.5% /* 2.624375rem */",
  letterSpacing: "0.015625rem",
});

export const StyledDetailPageButtonIconPanel = styled(Box, { label: "StyledDetailPageButtonIconPanel" })(
  ({ theme }) => ({
    display: "flex",
    alignItems: "flex-start",
    gap: "16px",
  })
);

export const StyledDetailPageButton = styled(Button, { label: "StyledDetailPageButton" })(
  ({ theme, disabled }: { theme?: Theme; disabled: boolean }) => ({
    display: "inline-flex",
    alignItems: "center",
    gap: "-8px",
    border: `1px solid ${ordinaryColors.functionalBlack900}`,
    opacity: disabled ? 0.5 : 1,
  })
);

export const StyledDetailPageButtonIcon = styled(SvgIcon, { label: "StyledDetailPageButtonIcon" })(({ theme }) => ({
  width: "24px",
  height: "24px",
  color: ordinaryColors.lightActionActive,
}));

export const StyledDetailPageButtonLabelBox = styled(Box, { label: "StyledDetailPageButtonLabelBox" })(({ theme }) => ({
  display: "flex",
  padding: "0px 16px",
  alignItems: "center",
  gap: "10px",
}));

export const StyledDetailPageButtonLabel = styled(Box, { label: "StyledDetailPageButtonLabel" })(({ theme }) => ({
  color: ordinaryColors.functionalBlack,
  fontFeatureSettings: "'clig' off, 'liga' off",
  fontSize: "18px",
  fontStyle: "normal",
  fontWeight: 400,
  lineHeight: "143% /* 25.74px */",
  letterSpacing: "0.17px",
}));

export const StyledFullTextDisplayContainer = styled(Box, { label: "StyledFullTextDisplayContainer" })(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  width: "100%",
  gap: "40px",
}));

export const StyledFullTextDisplayContentContainer = styled(Box, { label: "StyledFullTextDisplayContentContainer" })(
  ({ theme }) => ({
    display: "flex",
    width: "100%",
    flexDirection: "column",
    gap: "32px",
    padding: "8px 24px",
  })
);

export const StyledFullTextDisplayHeader = styled(Box, { label: "StyledFullTextDisplayHeader" })(({ theme }) => ({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "flex-start",
  alignSelf: "stretch",
}));

export const StyledFullTextDisplayHeaderTitle = styled(Box, { label: "StyledFullTextDisplayHeaderTitle" })(
  ({ theme }) => ({
    color: ordinaryColors.functionalBlack,
    fontFeatureSettings: "'clig' off, 'liga' off",
    fontSize: "24px",
    fontStyle: "normal",
    fontWeight: 500,
    lineHeight: "133.4% /* 32.016px */",
  })
);

export const StyledFullTextDisplay = styled(Box, { label: "StyledFullTextDisplay" })(({ theme }) => ({
  flex: "1 0 0",
  color: ordinaryColors.functionalBlack,
  fontFeatureSettings: "'clig' off, 'liga' off",
  fontSize: "18px",
  fontStyle: "normal",
  fontWeight: 400,
  lineHeight: "143% /* 25.74px */",
  letterSpacing: "0.17px",
}));

export const StyledFullTextDisplayButtonContainer = styled(Box, { label: "StyledFullTextDisplayButtonContainer" })(
  ({ theme }) => ({
    display: "flex",
    padding: "0px 32px",
    marginLeft: "auto",
    gap: "16px",
  })
);

export const StyledTabLabel = styled(Box, { label: "StyledTabLabel" })(({ theme }) => ({
  fontFamily: "Roboto",
  fontSize: "14px",
  fontStyle: "normal",
  fontWeight: 500,
  lineHeight: "24px /* 171.429% */",
  letterSpacing: "0.4px",
  textTransform: "uppercase",
}));

export const StyledTabPanelRoot = styled(Box, { label: "StyledTabPanelRoot" })(({ theme }) => ({
  marginTop: "32px",
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-start",
  gap: "32px",
  alignSelf: "stretch",
}));

export const StyledTabContentContainer = styled(Box, { label: "StyledTabContentContainer" })(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-start",
  gap: "12px",
  alignSelf: "stretch",
  background: "transparent",
}));

export const StyledUnitInformationRoot = styled(Box, { label: "StyledUnitInformationRoot" })(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-start",
  gap: "32px",
  alignSelf: "stretch",
}));

export const StyledUnitDetailsListRoot = styled(Box, { label: "StyledUnitDetailsListRoot" })(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  width: "100%",
  overflow: "hidden",
}));

export const StyledUnitDetailsTitle = styled(Box, { label: "StyledUnitDetailsTitle" })(({ theme }) => ({
  color: ordinaryColors.functionalBlack,
  fontFeatureSettings: "'clig' off, 'liga' off",
  fontSize: "24px",
  fontStyle: "normal",
  fontWeight: 500,
  lineHeight: "133.4% /* 32.016px */",
}));

export const StyledUnitDetailsContentContainer = styled(Box, { label: "StyledUnitDetailsContentContainer" })(
  ({ theme }) => ({
    display: "flex",
    width: "100%",
    gap: "48px",
    "@media (max-width: 1500px)": {
      flexDirection: "column",
    },
  })
);

export const StyledUnitDetailsListContainer = styled(Box, { label: "StyledUnitDetailsListContainer" })(({ theme }) => ({
  borderTop: `1px solid ${ordinaryColors.functionalDivider}`,
  background: ordinaryColors.functionalGreyBack,
}));

export const StyledUnitDetailsListItem = styled(Box, {
  label: "StyledUnitDetailsListItem",
  shouldForwardProp: (propName) => !["selectedUnitId", "cBU"].includes(propName.toString()),
})(({ theme, selectedUnitId, unitId }: { theme?: Theme; selectedUnitId: string; unitId: string }) => ({
  cursor: "pointer",
  display: "flex",
  height: "60px",
  padding: "8px 24px",
  alignItems: "center",
  gap: "10px",
  alignSelf: "stretch",
  borderRadius: "0px",
  borderBottom: `1px solid ${ordinaryColors.functionalDivider}`,
  background: unitId === selectedUnitId ? ordinaryColors.primary50 : ordinaryColors.functionalGreyBack,
  borderColor: unitId === selectedUnitId ? ordinaryColors.primaryBrand : "transparent",
  borderStyle: "solid",
  borderWidth: "0.0625rem",
  minWidth: "342px",
}));

export const StyledUnitDetailsListBox = styled(Box, {
  label: "StyledUnitDetailsListBox",
  shouldForwardProp: (propName) => !["selectedUnitId", "cBU"].includes(propName.toString()),
})(() => ({
  display: "flex",
  justifyContent: "space-between",
  width: "100%",
}));

export const StyledUnitDetailsListName = styled(Box, {
  label: "StyledUnitDetailsListName",
  shouldForwardProp: (propName) => !["selectedUnitId", "cBU", "isHighlighted"].includes(propName.toString()),
})(
  ({
    theme,
    selectedUnitId,
    unitId,
    isHighlighted,
  }: {
    theme?: Theme;
    selectedUnitId: string;
    unitId: string;
    isHighlighted: boolean;
  }) => ({
    display: "-webkit-box",
    WebkitBoxOrient: "vertical",
    WebkitLineClamp: 1,
    overflow: "hidden",
    color: unitId === selectedUnitId ? ordinaryColors.primaryBrand : ordinaryColors.functionalBlack,
    fontFeatureSettings: "'clig' off, 'liga' off",
    textOverflow: "ellipsis",
    fontSize: "18px",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "143% /* 25.74px */",
    letterSpacing: "0.17px",
    border: isHighlighted ? `1px solid ${ordinaryColors.statusTagWarning}` : "none",
    backgroundColor: isHighlighted ? ordinaryColors.warning100 : "transparent",
    padding: "0px 8px",
  })
);

export const StyledUnitParametersContainer = styled(Box, { label: "StyledUnitParametersContainer" })({
  display: "flex",
  width: "100%",
  overflowX: "auto",
  backgroundColor: ordinaryColors.functionalGreyBack,
});

export const StyledShowFullTextButton = styled(Button, { label: "StyledShowFullTextButton" })(
  ({ theme, disabled }: { theme?: Theme; disabled: boolean }) => ({
    height: "26px",
    color: ordinaryColors.primaryBrand,
    fontFeatureSettings: "'clig' off, 'liga' off",
    fontSize: "18px",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "143% /* 25.74px */",
    letterSpacing: "0.17px",
    opacity: disabled ? 0.5 : 1,
  })
);

export const StyledUploadedDocumentsTitle = styled(Box)(({ theme }) => ({
  color: ordinaryColors.functionalBlack1000,
  fontFeatureSettings: "'clig' off, 'liga' off",
  fontSize: "24px",
  fontStyle: "normal",
  fontWeight: 500,
  lineHeight: "133.4% /* 32.016px */",
}));

export const StyledMessage = styled(Box)(({ theme }) => ({
  color: ordinaryColors.grey600,
  fontFeatureSettings: "'clig' off, 'liga' off",
  fontSize: "16px",
  fontStyle: "normal",
  fontWeight: 400,
  lineHeight: "24px /* 150% */",
  letterSpacing: "0.17px",
}));

export const StyledDivider = styled(Box)(({ theme }) => ({
  height: "1px",
  alignSelf: "stretch",
  border: `1px solid ${"rgba(0, 0, 0, 0.12)"}`,
  margin: "16px 0",
}));

export const StyledBlockedFile = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  gap: "16px",
}));

export const StyledBlockedInfo = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  color: ordinaryColors.grey600,
  fontFeatureSettings: "'clig' off, 'liga' off",
  fontSize: "16px",
  fontStyle: "normal",
  fontWeight: 400,
  lineHeight: "24px /* 150% */",
  letterSpacing: "0.17px",
}));

export const customUnitsDetailsListStyles = {
  ".MuiOutlinedInput-root": {
    backgroundColor: ordinaryColors.grey200,
    width: "100%",
    height: "43px",
    borderRadius: "0px",
  },
  flexGrow: 0,
  display: "flex",
  alignItems: "center",
  // height: "85px",
  verticalAlign: "center",
  justifyContent: "center",
  paddingBottom: "0",
  ".MuiOutlinedInput-notchedOutline": {
    // borderWidth: "1px",
    borderColor: ordinaryColors.functionalLightGreyBack,
  },
  ".MuiOutlinedInput-input": {
    padding: 0,
    height: "36px",
  },

  ".MuiInputBase-adornedStart": { color: "rgba(0, 0, 0, 0.6)" },
  // ".MuiSvgIcon-root": { height: "20px", width: "20px" },
  ".MuiInputBase-input": {
    color: "rgba(0, 0, 0, 0.9)",
    marginLeft: "6px",
  },
};

export const StyledUnitParameterTitleContainer = styled(Box, { label: "StyledUnitParameterTitleBox" })(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  gap: "1rem",
  width: "100%",
}));
export const StyledUnitParamsTitleTypography = styled(Box, { label: "StyledUnitParamsTitleTypography" })(
  ({ theme }) => ({
    fontSize: "1.25rem",
    fontStyle: "normal",
    fontWeight: 500,
    padding: "24px 32px",
    display: "flex",
    alignItems: "baseline",
  })
);

export const StyledUnitParamsCodeTypography = styled("span", { label: "StyledUnitParamsTitleTypography" })(
  ({ theme }) => ({
    fontSize: "1rem",
    fontStyle: "normal",
    fontWeight: 400,
    paddingLeft: "1rem",
    flexShrink: 0,
  })
);
export const StyledUnitParamsTitleBox = styled(Box, { label: "StyledUnitParamsTitleBox" })(({ theme }) => ({
  display: "flex",
  alignItems: "flex-start",
  flexDirection: "column",
}));

export const StyledUnitParamsTitleButtonBox = styled(Box, { label: "StyledUnitParamsTitleButtonBox" })(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  paddingLeft: "22px",
}));

export const StyledUploadedDocsBox = styled(Box, {
  name: "StyledUploadedDocsBox",
  shouldForwardProp: (prop) => prop !== "isHighlighted",
})<StyledUploadedDocsBoxProps>(({ isHighlighted }) => ({
  border: isHighlighted ? `1px solid ${ordinaryColors.statusTagWarning}` : "none",
  backgroundColor: isHighlighted ? ordinaryColors.warning100 : "transparent",
  padding: "0px 8px",
}));

export const StyledHeaderTitleInnerWithInfo = styled(Box, {
  name: "StyledHeaderTitleInnerWithInfo",
  shouldForwardProp: (prop) => prop !== "isImported",
})<{ isImported?: boolean }>(({ isImported }) => ({
  display: "flex",
  gap: isImported ? "12px" : "0px",
  alignItems: "center",
}));

export const StyledHeaderTitleInner = styled(Box, { label: "StyledHeaderTitleInner" })(({ theme }) => ({
  fontSize: "27.4286px",
  display: "flex",
  overflow: "hidden",
}));
