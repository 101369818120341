import { useGetAccessRequestsForPermissionsManagementTableQuery } from "graphqlBase/accessRequest/__generated__/getAccessRequestsForPermissionsManagementTable";
import { useEffect } from "react";
import { accessPermissionRequestCountAtom } from "pages/AccessPermissionManagement/recoil";
import { useSetRecoilState } from "recoil";
import { useGetUserInfo } from "lib/userInfo";

const useGetAccessPermissions = () => {
  const userInfo = useGetUserInfo();
  const isPlatformOperator = userInfo?.role?.includes("PLATFORM_OPERATOR");
  const { data, loading, refetch } = useGetAccessRequestsForPermissionsManagementTableQuery({
    variables: {
      take: 0,
      where: {
        and: [
          { status: { eq: "REQUESTED" } },
          isPlatformOperator ? {} : { requestToTenantId: { eq: userInfo?.tenantId } },
        ],
      },
    },
    skip: !userInfo,
  });
  const setAccessPermissions = useSetRecoilState(accessPermissionRequestCountAtom);
  useEffect(() => {
    if (!loading && data) {
      setAccessPermissions(data.accessRequests?.totalCount ?? null);
    }
  }, [data, loading, setAccessPermissions]);

  return { loading, refetch };
};

export default useGetAccessPermissions;
