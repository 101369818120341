import {
  Checkbox,
  Divider,
  FormControl,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
  SelectChangeEvent,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import ResetFilterButton from "components/molecules/ResetFilterButton";
import { useRecoilState } from "recoil";
import translations from "translations";
import { SortingDropDownProps, sortSelectorFamily } from "../../State/manageFiltersInUnitsOverview";
import TitleWithFilterCounter from "../TitleWithFilterCounter";
interface EnumProps {
  filterType: keyof SortingDropDownProps;
  title: string;
}

const SortingDropDown: React.FC<EnumProps> = ({ filterType, title }) => {
  const [filters, setFilters] = useRecoilState(sortSelectorFamily(filterType));

  const resetFilters = () => setFilters("");
  const handleChange = (event: SelectChangeEvent<string>) => {
    const value = event.target.value;
    setFilters(value);
  };

  const options = [
    { label: translations.enums.sort.ASC, value: "ASC" },
    { label: translations.enums.sort.DESC, value: "DESC" },
    { label: translations.enums.sort.Default, value: "" },
  ];

  return (
    <FormControl sx={{ m: 1 }} size="small" aria-label={filterType}>
      <Select
        aria-label={filterType}
        labelId={filterType}
        id={filterType}
        displayEmpty
        sx={{ borderRadius: "0px" }}
        value={filters}
        onChange={handleChange}
        input={<OutlinedInput />}
        renderValue={(selected) => {
          if (selected === "" || selected === undefined) {
            return <Typography>{title}</Typography>;
          }
          return <TitleWithFilterCounter selectedSorting={selected} title={title} />;
        }}
      >
        {options.map(({ label, value }) => (
          <MenuItem key={value} value={value} sx={{ borderRadius: "0px" }}>
            <Checkbox checked={filters === value} />
            <ListItemText primary={label} />
          </MenuItem>
        ))}
        <Divider />
        <Box sx={{ display: "flex", justifyContent: "center" }}>
          <ResetFilterButton title={translations.globals.button.reset} resetFilters={resetFilters} />
        </Box>
      </Select>
    </FormControl>
  );
};

export default SortingDropDown;
