import StatusTag from "components/molecules/StatusTag/StatusTag";
import ordinaryColors from "themes/ordinaryColors";
import translations from "translations";

type SwitchStatusProps = {
  status: string | undefined;
  replacedByCertificate?: { certificateNumber: string; id: string };
};

export default function switchStatus({ status, replacedByCertificate }: SwitchStatusProps) {
  switch (status) {
    case "Valid / Ongoing":
      return (
        <StatusTag
          // title={translations.pages.certificationManagement.certificateStatuses.valid}
          title={translations.entities.certificateValidityStatus["c47a0b7c-701b-5544-92b0-6bcb48aac633"]}
          backgroundColor={ordinaryColors.sucess200}
          color={ordinaryColors.grey900}
          borderColor="transparent"
        />
      );
    case "Expired":
    case "Expired / Invalid":
      return (
        <StatusTag
          // title={translations.pages.certificationManagement.certificateStatuses.expired}
          title={translations.entities.certificateValidityStatus["63a507c8-7124-5eec-8510-02b74a3a8dca"]}
          color={ordinaryColors.errorMain}
        />
      );
    case "Withdrawn (replaced by)":
      return (
        <StatusTag
          // title={translations.pages.certificationManagement.certificateStatuses.replaced}
          title={translations.entities.certificateValidityStatus["38fa47c8-12a4-5e6f-b9d1-387d3c8222b8"]}
          color={ordinaryColors.grey700}
          replacedByCertificate={replacedByCertificate}
        />
      );
    case "Withdrawn (not replaced)":
      return (
        <StatusTag
          // title={translations.pages.certificationManagement.certificateStatuses.withdrawn}
          title={translations.entities.certificateValidityStatus["64e89d04-9533-57f4-8f2d-41f340a29d8b"]}
          color={ordinaryColors.errorMain}
        />
      );
    case "Void":
      return (
        <StatusTag
          title={translations.entities.certificateValidityStatus["258a9d1a-5070-562a-a14f-2973c2c70d84"]}
          color={ordinaryColors.errorMain}
        />
      );

    default:
      return <p></p>;
  }
}
