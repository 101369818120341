import type * as Types from '../../types';

import client from "apollo/client";
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetTenantsForRoleQueryVariables = Types.Exact<{
  role: Types.TenantRole;
  inputValue?: Types.InputMaybe<Types.Scalars['String']>;
  approvalStatus?: Types.InputMaybe<Array<Types.ApprovalStatus> | Types.ApprovalStatus>;
}>;


export type GetTenantsForRoleQuery = { __typename?: 'Query', tenants?: { __typename?: 'TenantCollectionSegment', items?: Array<{ __typename?: 'Tenant', id: string, tenantName: string, tenantRole: Types.TenantRole, approvalStatus: Types.ApprovalStatus }> | null } | null };


        export type ExecGetTenantsForRoleOptions = Omit<
        Apollo.QueryOptions<
        GetTenantsForRoleQueryVariables, GetTenantsForRoleQuery
        >,
        "query"
      >;
    export const execGetTenantsForRoleQuery = (options: ExecGetTenantsForRoleOptions) =>
    client.query< GetTenantsForRoleQuery, GetTenantsForRoleQueryVariables>({
      ...options,
      query: GetTenantsForRoleDocument,
    });
    

export const GetTenantsForRoleDocument = gql`
    query getTenantsForRole($role: TenantRole!, $inputValue: String, $approvalStatus: [ApprovalStatus!]) {
  tenants(
    where: {tenantName: {contains: $inputValue}, approvalStatus: {in: $approvalStatus}, tenantRole: {eq: $role}}
  ) {
    items {
      id
      tenantName
      tenantRole
      approvalStatus
    }
  }
}
    `;

/**
 * __useGetTenantsForRoleQuery__
 *
 * To run a query within a React component, call `useGetTenantsForRoleQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTenantsForRoleQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTenantsForRoleQuery({
 *   variables: {
 *      role: // value for 'role'
 *      inputValue: // value for 'inputValue'
 *      approvalStatus: // value for 'approvalStatus'
 *   },
 * });
 */
export function useGetTenantsForRoleQuery(baseOptions: Apollo.QueryHookOptions<GetTenantsForRoleQuery, GetTenantsForRoleQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetTenantsForRoleQuery, GetTenantsForRoleQueryVariables>(GetTenantsForRoleDocument, options);
      }
export function useGetTenantsForRoleLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetTenantsForRoleQuery, GetTenantsForRoleQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetTenantsForRoleQuery, GetTenantsForRoleQueryVariables>(GetTenantsForRoleDocument, options);
        }
export type GetTenantsForRoleQueryHookResult = ReturnType<typeof useGetTenantsForRoleQuery>;
export type GetTenantsForRoleLazyQueryHookResult = ReturnType<typeof useGetTenantsForRoleLazyQuery>;
export type GetTenantsForRoleQueryResult = Apollo.QueryResult<GetTenantsForRoleQuery, GetTenantsForRoleQueryVariables>;
export type PartialResultType<T> = Types.PartialResultType<T>;
          export type ResultType<T> = Types.ResultType<T>;
          export type PaginatedResultType<T> = Types.PaginatedResultType<T>;
          export type NonMaybe<T> = Types.NonMaybe<T>;
          export type Maybe<T> = Types.Maybe<T>;