import { bold, center, LegalNoteLink, nextLine, nextParagraph } from "components/mlpaUiComponents/LegalNote/lib";

export default {
  databaseCardLabel: "Einheiten und Zertifikate",
  databaseCardText:
    "Greifen Sie sofort auf das öffentliche Register zu, um öffentlich verfügbare Einheitenzertifikate anzuzeigen",
  days: "Tage",
  faq: "FAQ",
  faqMessage: "Erfahren Sie mehr über Themen, die Sie wahrscheinlich interessieren.",
  headline: "ZEREZ - Zentrales Register für Einheiten- und Komponentenzertifikate",
  hours: "Stunden",
  logInCardLabel: "Anmelden",
  logInCardText: "Melden Sie sich einfach mit Ihrem Benutzernamen und Passwort an, um auf alle Funktionen zuzugreifen",
  minutes: "Minuten",
  otherDocuments: "Hinweise, Vorlagen und Dokumente",
  otherDocumentsMessage: "Hier finden Sie Hinweise, Vorlagen und Dokumente zur Nutzung des Registers.",
  ourVision: "Our Vision",
  ourVisonMessage: "Sehen Sie, für wen ZEREZ hauptsächlich konzipiert ist und welche Ziele dieses Projekt verfolgt.",
  question: "Was möchten Sie tun?",
  registrationCardLabel: "Registrierung",
  registrationCardText: "Sie haben noch kein Konto? Klicken Sie hier, um eines zu erstellen.",
  seconds: "Sekunden",
  countdownTo: "Countdown bis zum Start der verpflichtenden Nutzungsphase",
  linkToRegister: "Link zur Registrierung",
  linkToLogin: "Link zur Anmeldung",
  linkToUnitsAndCertificates: "Link zu Einheiten und Zertifikaten",
  linkToFaqs: "Link zu FAQs",
  linkToOtherDocuments: "Link zu Hinweisen, Vorlagen und Dokumenten",
  textBlockOne: `
    Willkommen beim Zentralen Register für Einheiten- und Komponentenzertifikate (ZEREZ) der FGW e. V. 
    ${nextParagraph()}In ZEREZ werden die Einheiten- und Komponentenzertifikate aller Spannungsebenen an einem Ort gesammelt. Diese Nachweise über die elektrotechnischen Eigenschaften von Erzeugungseinheiten und elektrischen Komponenten können hier von den berechtigten und interessierten Stellen eingetragen und abgerufen werden.
    ${nextParagraph()}Nach der ${LegalNoteLink({
    url: "https://www.gesetze-im-internet.de/nelev/BJNR165100017.html",
    ariaLabel: "Link zu",
    label: "Elektrotechnische-Eigenschaften-Nachweis-Verordnung (NELEV)",
  })} des Bundesministeriums für Wirtschaft und Klimaschutz (BMWK) sind die Marktteilnehmer seit dem 1. Februar 2025 zur Nutzung des zentralen Registers verpflichtet (vgl. § 7 Absatz 4 NELEV).
  `,
  textBlockTwo: ({
    certificateCount,
    unitCount,
  }: {
    certificateCount: number | undefined;
    unitCount: number | undefined;
  }) => `
    ${
      certificateCount &&
      `${center(bold(`Im Register sind z.Z. ${certificateCount} Zertifikate und ${unitCount} Einheiten hinterlegt.`))}`
    }
    ${nextLine()}Die FGW e. V. ist seit dem 17. Juli 2024 als beliehene Stelle mit dem Betrieb des ZEREZ betraut. Rechtliche Grundlage für die Beleihung ist § 49d Absatz 3 Satz 1 des Energiewirtschaftsgesetzes (EnWG).
    ${nextParagraph()}Einheitenzertifikate können von Herstellern und Zertifizierungsstellen veröffentlicht und verwaltet werden. Eine angepasste Datenschnittstelle ermöglicht es Netzbetreibern, alle verfügbaren Zertifikate zu verarbeiten.
    ${nextParagraph()}${center(
    `Bei Fragen oder Anregungen kontaktieren Sie uns gern. Zusätzlich stehen Ihnen ein FAQ sowie weitere Hinweise zur Verfügung.`
  )}
    ${nextLine()}${center(
    bold(`Ein von Ihnen gewünschtes Zertifikat fehlt? – Kontaktieren Sie gerne Ihren Hersteller und weisen darauf hin.`)
  )}
  `,
};
