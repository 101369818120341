import type * as Types from '../../types';

import client from "apollo/client";
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetTenantsQueryVariables = Types.Exact<{
  skip?: Types.InputMaybe<Types.Scalars['Int']>;
  take?: Types.InputMaybe<Types.Scalars['Int']>;
  where?: Types.InputMaybe<Types.TenantFilterInput>;
  order?: Types.InputMaybe<Array<Types.TenantSortInput> | Types.TenantSortInput>;
}>;


export type GetTenantsQuery = { __typename?: 'Query', tenants?: { __typename?: 'TenantCollectionSegment', totalCount: number, items?: Array<{ __typename?: 'Tenant', id: string, tenantName: string, tenantRole: Types.TenantRole, streetAndNumber?: string | null, postalCode?: string | null, city?: string | null, country?: string | null, approvalStatus: Types.ApprovalStatus }> | null } | null };


        export type ExecGetTenantsOptions = Omit<
        Apollo.QueryOptions<
        GetTenantsQueryVariables, GetTenantsQuery
        >,
        "query"
      >;
    export const execGetTenantsQuery = (options: ExecGetTenantsOptions) =>
    client.query< GetTenantsQuery, GetTenantsQueryVariables>({
      ...options,
      query: GetTenantsDocument,
    });
    

export const GetTenantsDocument = gql`
    query getTenants($skip: Int, $take: Int, $where: TenantFilterInput, $order: [TenantSortInput!]) {
  tenants(skip: $skip, take: $take, where: $where, order: $order) {
    items {
      id
      tenantName
      tenantRole
      streetAndNumber
      postalCode
      city
      country
      approvalStatus
    }
    totalCount
  }
}
    `;

/**
 * __useGetTenantsQuery__
 *
 * To run a query within a React component, call `useGetTenantsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTenantsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTenantsQuery({
 *   variables: {
 *      skip: // value for 'skip'
 *      take: // value for 'take'
 *      where: // value for 'where'
 *      order: // value for 'order'
 *   },
 * });
 */
export function useGetTenantsQuery(baseOptions?: Apollo.QueryHookOptions<GetTenantsQuery, GetTenantsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetTenantsQuery, GetTenantsQueryVariables>(GetTenantsDocument, options);
      }
export function useGetTenantsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetTenantsQuery, GetTenantsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetTenantsQuery, GetTenantsQueryVariables>(GetTenantsDocument, options);
        }
export type GetTenantsQueryHookResult = ReturnType<typeof useGetTenantsQuery>;
export type GetTenantsLazyQueryHookResult = ReturnType<typeof useGetTenantsLazyQuery>;
export type GetTenantsQueryResult = Apollo.QueryResult<GetTenantsQuery, GetTenantsQueryVariables>;
export type PartialResultType<T> = Types.PartialResultType<T>;
          export type ResultType<T> = Types.ResultType<T>;
          export type PaginatedResultType<T> = Types.PaginatedResultType<T>;
          export type NonMaybe<T> = Types.NonMaybe<T>;
          export type Maybe<T> = Types.Maybe<T>;