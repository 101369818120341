import * as React from "react";
import Props from "./index.d";
import { useRecoilState } from "recoil";
import { getUnitCreateFormAtom } from "raft/UnitForm";
import { UnitInputCreate } from "raft/internal/schemaTypes";
import { unitDefaultValues } from "pages/UploadCertificate/Layout/RightPannel/UnitForm/ConnectedUnitForm";

const SyncTG8Rev25ComformityWithSharedValues: React.FC<Props> = (props) => {
  const { data } = props;

  const createUnitAtom = getUnitCreateFormAtom({});
  const [unitValues, setUnitValues] = useRecoilState(createUnitAtom);
  React.useEffect(() => {
    if (data.state !== unitValues?.__withTG8Rev25Conformity) {
      setUnitValues((prevValues) => {
        const updatedValues = {
          ...unitDefaultValues,
          ...prevValues,
          __withTG8Rev25Conformity: data.state,
        };

        return updatedValues as UnitInputCreate;
      });
    }
  }, [unitValues, setUnitValues, data]);
  return null;
};

export default SyncTG8Rev25ComformityWithSharedValues;
