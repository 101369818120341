export default {
  label: "Error Reports",
  description: "Description",
  certificateHolder: "Certificate Holder",
  status: "Status",
  errorType: "Error Type",
  reportedOn: "Reported On",
  reportedBy: "Reported By",
  resolvedOn: "Resolved On",
  resolvedBy: "Resolved By",
  openSince: "Open Since (Days)",
};
