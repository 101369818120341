// eslint-disable-next-line import/no-extraneous-dependencies
import { deepmerge } from "@mui/utils";
import { UnitOverviewSortInput } from "graphqlBase/types";
import { useMemo } from "react";
import { useRecoilValue } from "recoil";
import { unitsOverviewFilterAtom } from "../State/manageFiltersInUnitsOverview";

const deepmergeSave = (a: { sortings: UnitOverviewSortInput }, b: { sortings: UnitOverviewSortInput }) =>
  deepmerge(a, b, { clone: true });

type FilterBuilderVariables = {
  sortings: UnitOverviewSortInput;
};
export const useSortBuilder = () => {
  const sortValues = useRecoilValue(unitsOverviewFilterAtom);
  const sortValuesForCustomizedFilters = useMemo(
    () =>
      Object.entries(sortValues).reduce<FilterBuilderVariables>(
        (sortings, [key, filterValue]) => {
          if (!filterValue) return sortings;

          if (filterValue.length > 0 && key === "createdAt") {
            const createdAtSort = {
              createdAt: filterValue,
            };

            const nextFilter = deepmergeSave(sortings, { sortings: createdAtSort });
            return nextFilter;
          }

          if (filterValue.length > 0 && key === "modifiedAt") {
            const modifiedAtSort = {
              modifiedAt: filterValue,
            };

            const nextFilter = deepmergeSave(sortings, { sortings: modifiedAtSort });
            return nextFilter;
          }

          return sortings;
        },
        { sortings: {} } as { sortings: UnitOverviewSortInput }
      ),
    [sortValues]
  );
  return sortValuesForCustomizedFilters;
};
