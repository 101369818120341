import styled from "@emotion/styled";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import CloseIcon from "@mui/icons-material/Close";
import EditIcon from "@mui/icons-material/Edit";
import { Box, Button, IconButton, TextField, Typography } from "@mui/material";
import ModalWithButtons from "components/molecules/Modal/ModalWithButtons";
import { dummyIdPrefix } from "pages/UploadCertificate/config";
import {
  getFileTypeIdForFileTypeName,
  uploadCertificateFilesMultiSelectorFamily,
} from "pages/UploadCertificate/state/uploadCertificateFileState";
import { UploadCertificateFile } from "pages/UploadCertificate/types";
import { useCallback, useState } from "react";
import { useDropzone } from "react-dropzone";
import { useRecoilState, useSetRecoilState } from "recoil";
import ordinaryColors from "themes/ordinaryColors";
import translations from "translations";
import { v4 as uuidv4 } from "uuid";
import { toBase64 } from "./FileUtils";
import Props from "./index.d";
import UploadedFileIcon from "./UploadedFileIcon";
export const titleFileStyle = {
  width: "100%",
  color: ordinaryColors.lightTextPrimary,
  fontFeatureSettings: "'clig' off, 'liga' off",
  fontSize: "16px",
  fontStyle: "normal",
  fontWeight: 400,
  lineHeight: "175% /* 28px */",
  letterSpacing: "0.15px",
};
export const StyledUploadButton = styled(Button)(({ theme }) => ({
  height: "43px",
  width: "100%",
  borderRadius: "0px",
  variant: "outlined",
  border: "1px solid",
  borderColor: ordinaryColors.functionalBlack,
  minWidth: "64px",
  color: ordinaryColors.functionalBlack,
}));

const validFilenameRegex = /^[^\\/:*?"<>|]+$/;

const EditFileNameModal = ({
  onClose,
  onConfirm,
  fileName,
  setFileName,
  originalFileName,
}: {
  onClose: () => void;
  fileName?: string;
  setFileName: (value: string) => void;
  onConfirm: () => void;
  originalFileName: string;
}) => {
  const fileExtensionParts = fileName?.split(".");
  const extension = fileExtensionParts?.pop();
  const fileNameWithoutExtension = (fileExtensionParts ?? []).join(".");

  return (
    <ModalWithButtons
      openModal={!!fileName}
      onClose={onClose}
      title={translations.pages.certificateUpload.renameFile}
      addButtonTitle={translations.globals.button.confirm}
      addButtonClick={fileName !== originalFileName ? onConfirm : onClose}
      width="715px"
    >
      <TextField
        sx={{
          height: "35px",
          width: "100%",
          padding: "0px",
          marginBottom: "14px",
          borderRadius: "0px",
          "& .MuiFormHelperText-root": {
            marginLeft: "0px",
            fontSize: "12px",
            color: ordinaryColors.error,
          },
          " .MuiOutlinedInput-notchedOutline": {
            borderWidth: "0px",
          },
          "& .MuiOutlinedInput-input": {
            padding: "0.5rem 0.75rem",
            width: "100%",
          },
          "& .MuiOutlinedInput-root": {
            borderRadius: "0px",
            border: "1px solid #9E9E9E66",
            backgroundColor: "transparent",
            padding: "0px",
            "& fieldset": {
              borderRadius: "0px",
            },
          },
        }}
        // label={translations.pages.certificateUpload.renameFile}
        value={fileNameWithoutExtension}
        onChange={(e) => {
          if (validFilenameRegex.test(e.target.value)) {
            setFileName(`${e.target.value}.${extension}`);
          }
        }}
      />
    </ModalWithButtons>
  );
};

const FilesList = ({
  files,
  removeFile,
  setEditFileName,
  setOriginalFileName,
}: {
  files: UploadCertificateFile[];
  removeFile: (fileId: string | undefined, dummyCerificateId: string) => () => void;
  setEditFileName: React.Dispatch<React.SetStateAction<UploadCertificateFile | undefined>>;
  setOriginalFileName: React.Dispatch<React.SetStateAction<UploadCertificateFile | undefined>>;
}) => {
  return (
    <Box sx={{ gap: "0.5rem", display: "flex", flexDirection: "column" }}>
      {files.map((file) => (
        <Box
          key={file.id ?? file.certificateId}
          sx={{ display: "flex", alignItems: "center", justifyContent: "space-around" }}
        >
          <UploadedFileIcon {...file} />
          <Box sx={{ display: "flex", marginLeft: "2rem", width: "100%" }}>
            <Typography sx={titleFileStyle}>{file.certificateFileName}</Typography>
          </Box>
          <Box sx={{ display: "flex", justifyContent: "flex-end", marginTop: "-20px" }}>
            <IconButton
              disableRipple
              onClick={() => {
                setEditFileName(file);
                setOriginalFileName(file);
              }}
            >
              <EditIcon />
            </IconButton>
            <IconButton disableRipple onClick={removeFile(file.id, file.certificateId)}>
              <CloseIcon />
            </IconButton>
          </Box>
        </Box>
      ))}
    </Box>
  );
};

const CertificateFileUploadMulti: React.FC<Props> = (props) => {
  const { fileType, className, formAtom } = props;

  const [editFileName, setEditFileName] = useState<UploadCertificateFile | undefined>(undefined);
  const [filesToUpload, setFilesToUpload] = useRecoilState(uploadCertificateFilesMultiSelectorFamily(fileType));
  const [originalFileName, setOriginalFileName] = useState<UploadCertificateFile | undefined>(undefined);
  const setFormState = useSetRecoilState(formAtom);

  const setFileName = (fileName: string) => {
    setEditFileName((file) => {
      if (!file) return;
      return { ...file, certificateFileName: fileName };
    });
  };
  const stopEdit = () => {
    setEditFileName(undefined);
  };

  const onConfirm = () => {
    setFormState((updateModifiedByUser) => {
      return { ...updateModifiedByUser, __modifiedByUser: 2 };
    });

    setFilesToUpload((files) => {
      if (!editFileName) return files;
      if (editFileName.id === undefined) {
        return files.map((file) => (file.certificateId === editFileName.certificateId ? editFileName : file));
      }
      return files.map((file) => (file.id === editFileName.id ? editFileName : file));
    });
    setEditFileName(undefined);
  };

  const onDrop = useCallback(
    async (acceptedFiles: File[]) => {
      const converts = acceptedFiles.map<Promise<UploadCertificateFile>>(async (file) => {
        const certificateFileName = file.name;
        const certificateFileTypeId = getFileTypeIdForFileTypeName(fileType);
        const certificateId = `${dummyIdPrefix}${uuidv4()}`;
        const base64FileString = await toBase64([file]);
        return { certificateFileName, certificateFileTypeId, certificateId, base64FileString, size: file.size };
      });
      const filesToAdd = await Promise.all(converts);
      setFilesToUpload((files) => [...(files ?? []), ...filesToAdd]);
    },
    [fileType, setFilesToUpload]
  );

  const removeFile = (fileId: string | undefined, dummyCerificateId: string) => () => {
    if (fileId) {
      setFilesToUpload((files) => (files ?? []).filter((file) => file.id !== fileId));
    } else {
      setFilesToUpload((files) => (files ?? []).filter((file) => file.certificateId !== dummyCerificateId));
    }
  };

  const { open, getInputProps } = useDropzone({
    onDrop,
    noDrag: true,
    accept: props.mimeTypes.reduce((acc, mimeType) => ({ ...acc, [mimeType]: [] }), {}),
    multiple: true,
    useFsAccessApi: false,
  });
  return (
    <Box className={className}>
      <EditFileNameModal
        fileName={editFileName?.certificateFileName}
        originalFileName={originalFileName?.certificateFileName ?? ""}
        setFileName={setFileName}
        onConfirm={onConfirm}
        onClose={stopEdit}
      />
      <FilesList
        files={filesToUpload ?? []}
        removeFile={removeFile}
        setEditFileName={setEditFileName}
        setOriginalFileName={setOriginalFileName}
      />
      <StyledUploadButton variant="outlined" onClick={open} startIcon={<AttachFileIcon />}>
        <input {...getInputProps()} />
        {props.title ?? translations.globals.button.uploadGridConnectionValues}
      </StyledUploadButton>
    </Box>
  );
};
export default CertificateFileUploadMulti;
