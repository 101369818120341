import type * as Types from '../../types';

import client from "apollo/client";
import { gql } from '@apollo/client';
export type AccessRequestsFragementFragment = { __typename?: 'AccessRequest', id: string, certificateId: string, referenceId: string, referenceName: string, status: Types.AccessStatus, scope: string, reason?: string | null, requestedBy: string, requestedOn: string, approvedBy?: string | null, approvedOn?: string | null, rejectedBy?: string | null, rejectedOn?: string | null, accessForTenantId: string, createdAt: string, createdBy?: string | null, modifiedAt?: string | null, modifiedBy?: string | null, tenantId: string, accessRequestedTo: string, typeOfRequestedData: string, requestToTenantId: string, requestedByEmail?: string | null, certificate: { __typename?: 'Certificate', id: string, certificateNumber: string } };

export type CertificateHeaderAccessRequestFragment = { __typename?: 'Certificate', id: string, certificateNumber: string };

{/*  not supported */}
export const CertificateHeaderAccessRequestFragmentDoc = gql`
    fragment certificateHeaderAccessRequest on Certificate {
  id
  certificateNumber
}
    `;
export const AccessRequestsFragementFragmentDoc = gql`
    fragment accessRequestsFragement on AccessRequest {
  id
  certificateId
  referenceId
  referenceName
  status
  scope
  reason
  requestedBy
  requestedOn
  approvedBy
  approvedOn
  rejectedBy
  rejectedOn
  accessForTenantId
  createdAt
  createdBy
  modifiedAt
  modifiedBy
  tenantId
  accessRequestedTo
  typeOfRequestedData
  requestToTenantId
  requestedByEmail
  certificate {
    ...certificateHeaderAccessRequest
  }
}
    ${CertificateHeaderAccessRequestFragmentDoc}`;
export type PartialResultType<T> = Types.PartialResultType<T>;
          export type ResultType<T> = Types.ResultType<T>;
          export type PaginatedResultType<T> = Types.PaginatedResultType<T>;
          export type NonMaybe<T> = Types.NonMaybe<T>;
          export type Maybe<T> = Types.Maybe<T>;