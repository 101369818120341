import { FormHelperText } from "@mui/material";
import { uploadFilesAggregatedSizeSelector } from "pages/UploadCertificate/state/uploadCertificateFileState";
import * as React from "react";
import { useRecoilValue } from "recoil";
import ordinaryColors from "themes/ordinaryColors";
import Props from "./index.d";

const AggregatedFileSize: React.FC<Props> = (props) => {
  const {
    data: { setstate },
    className,
    errorManager,
  } = props;
  const size = useRecoilValue(uploadFilesAggregatedSizeSelector);
  setstate(size);

  const errorManagerRes = errorManager({
    errorJoiner: (errors) => {
      return errors.join(", ");
    },
  });
  if (errorManagerRes.error)
    return (
      <FormHelperText
        className={className}
        sx={{ color: ordinaryColors.error, marginLeft: "0px", fontSize: "16px", margin: "2rem 0 0.5rem" }}
      >
        {errorManagerRes.helperText}
      </FormHelperText>
    );
  return null;
};

export default AggregatedFileSize;
