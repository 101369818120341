export default {
  animation: {
    description:
      "Ihre Filter haben keine Ergebnisse ergeben. Bitte versuchen Sie es erneut, indem Sie Ihre Filter anpassen oder löschen.",
    title: "Keine passenden Suchergebnisse",
  },
  zerezIdforUnit: "ZEREZ ID der Einheit",
  zerezIdforCertificate: "ZEREZ ID des Zertifikates",
  certificateCheck: "Zertifikatsprüfung",
  certificateHistory: "Zertifikatsverlauf",
  certificateHistoryClose: "Zertifikatsverlauf schließen",
  certificateHistoryIssues: {
    "4e7118b6-4883-567a-b4ad-ab245717ff08": "Fehlerbericht erstellt",
    "9524be5a-6656-548a-a3c1-754b20df564a": "Zertifikat verifiziert",
    "b3ef1a67-6644-568f-b8d2-898b2d897562": "Zertifikat hochgeladen",
    "b01df1c0-0ea3-5313-bd0c-54931887c428": "Fehlerbericht geprüft",
  },
  certificateHistoryIssueTypesAndStatus: {
    "4e7118b6-4883-567a-b4ad-ab245717ff08": {
      // error report
      default: "Fehlerbericht",
    },
    "9524be5a-6656-548a-a3c1-754b20df564a": {
      // certificate validation
      "2cb21769-d5e7-5c7b-92a9-66c7cec83c4a": "Zertifikat hochgeladen", // new
      "c5aecfb8-5509-581c-a020-ee346f9775e2": "Zertifikat editiert", // open
      "dc7a63d2-6f9c-5604-ab6b-5e35656d017f": "Zertifikat verifiziert", // closed
      default: "Zertifikatsvalidierung",
    },
    "b3ef1a67-6644-568f-b8d2-898b2d897562": {
      // import
      default: "Import",
    },
    "b01df1c0-0ea3-5313-bd0c-54931887c428": {
      // error correction
      "c5aecfb8-5509-581c-a020-ee346f9775e2": "Zertifikat editiert", // open
      "13ef0cf5-fccb-5293-b7a6-7b1bdbe40da7": "Zertifikat editiert", // in progress
      "dc7a63d2-6f9c-5604-ab6b-5e35656d017f": "Zertifikat verifiziert", // closed
      default: "Fehlerberichtigung",
    },
  },
  certificateInfo: "Zertifikatsinformationen",
  certificateStatus: "Zertifikatstatus",
  certificateStatuses: {
    expired: "Abgelaufen",
    expiring: "Läuft ab",
    replaced: "Zurückgezogen (ersetzt durch)",
    valid: "Gültig",
    withdrawn: "Zurückgezogen (nicht ersetzt)",
  },
  description:
    "Die FGW e.V. vernetzt die Energiebranche und vereinheitlicht außerdem die Fachsprache der dezentralen und erneuerbaren Energien. In diesem Abschnitt finden Sie die Einheiten- und Komponentenzertifikate für die verschiedene Spannungsebenen. Hier können Sie überprüfen, ob diese Zertifikate von einer akkreditierten Zertifizierungsstelle geprüft wurden und ihren aktuellen Gültigkeitsstatus einsehen.",
  editParameters: "Parameter bearbeiten",
  editCertificate: "Zertifikat bearbeiten",
  errorNote: ({ entity, modelName }: { entity: "unit" | "certificate"; modelName: string | undefined }) => {
    let errorNote = `Bitte beschreiben Sie das Problem, das Sie bei dem Zertifikat ${modelName} festgestellt haben. Sobald das Problem von der verantwortlichen Partei behoben wurde, werden Sie per E-Mail benachrichtigt.`;
    if (entity === "unit")
      errorNote = `Bitte beschreiben Sie das Problem, das Sie bei dem Gerät ${modelName} festgestellt haben. Sobald das Problem von der verantwortlichen Partei behoben wurde, werden Sie per E-Mail benachrichtigt.`;
    return errorNote;
  },
  unitEditWarning: {
    header: "Bitte verlassen Sie den Bearbeitungsmodus, um fortzufahren.",
    info: "Sie bearbeiten derzeit die Parameter einer Einheit. Mögliche Änderungen gehen möglicherweise verloren. Bitte verlassen Sie den Bearbeitungsmodus, um fortzufahren.",
  },
  filterTitles: {
    certificateStatus: "Zertifikatstatus",
    certificateType: "Nachweisdokument",
    authority: "Zertifizierungsstelle",
    manufacturer: "Hersteller",
    maxActivePower: "Maximale Wirkleistung",
    norm: "Norm",
    ownCertificates: "Eigene Zertifikate",
    primaryEnergy: "Primärenergie",
    ratedPower: "Wirkleistung",
    ratedVoltage: "Bemessungsspannung",
    subscribed: "Abonniert",
    verified: "Verifiziert",
    isNotReplaced: "Nur aktuelle Revisionen",
    zerezId: "ZEREZ ID",
    sortByCreatedAt: "Erstellungsdatum (Zertifikat)",
    sortByModifiedAt: "Änderungsdatum (Zertifikat)",
  },
  label: "Einheiten und Zertifikate",
  locked: "Gesperrt",
  mostRecentCertificate: "Neuestes Zertifikat",
  notVerified: "Nicht Verifiziert",
  verificationPending: "Verifizierung ausstehend",
  isErrorReportedUnresolved: "Offener Fehlerbericht",
  verify: "Verifizieren",
  referToDetailView: "siehe Detailansicht",
  reportCertificateError: "Zertifikatsfehler melden",
  showUnit: "Zur Einheit",
  since: "seit",
  subscribed: "Abonniert",
  subscribeStar: "Abonnement",
  subscribe: "Abonnieren",
  title: "ZEREZ - Zentrales Register für Einheiten- und Komponentenzertifikate",
  unitParameters: {
    netFeed: "Typen Information",
    electricalParameters: "Elektrische Parameter",
    fastVoltageChanges: "Schnelle Spannungsänderungen",
    operation1: "Schaltvorgang 1",
    operation2: "Schaltvorgang 2",
    operation3: "Schaltvorgang 3",
    flicker: "Flicker",
    generalInfo: "Allgemeine Informationen",
    label: "Geräteparameter",
    powerRanking: "Leistungsbewertung",
  },
  uploadedDocuments: "Hochgeladene Dokumente",
  units: "Einheiten",
  noUnitsFound: "Zu ihrer Sucheingabe wurden keine Einheiten gefunden",
  verified: "Verifiziert",
  inCorrection: "Feedbackverfahren läuft",
  linkToDetailPageOfModel: "Link zur Detailseite der Einheit",
  listOfConnectionValues: "Liste der Netzanschlusswerte",
  zerezIDTextforPowerRange: "siehe Schieber für Leistungsbereich",
  requestLockedData: {
    sendRequest: "Anfrage senden",
    title: "Zugriff anfordern",
    selectAll: "Alle auswählen",
    selected: "ausgewählt",
    accessRequested: "...Zugriff angefordert",
    accessDenied: "...Zugriff abgelehnt",
    noRequestablesSelected:
      "Um Zugriff anzufordern, wählen Sie bitte die Art der Informationen aus, auf die Sie zugreifen möchten.",
    requestConnectionValuesText:
      "Ich möchte Zugriff auf die vollständige Liste der Netzwerkverbindungswerte für alle Einheiten haben, die von diesem Zertifikat abgedeckt sind.",
    accessApprovedConnectionValuesText: "Sie haben Zugriff auf die vollständige Liste der Netzwerkverbindungswerte",
    requestAccesNetworkConnectionValues:
      "Mit Ihrer aktuellen Berechtigung haben Sie keinen Zugriff auf die unten aufgeführten Daten. Um diese einsehen zu können, fordern Sie Zugriff an.",
    requestAccesNetworkConnectionValuesPending:
      "Der Zugriff auf einige gesperrte Dokumente wurde bereits von Ihnen oder einem Mitglied Ihrer Organisation angefordert. Sie erhalten eine E-Mail-Benachrichtigung, sobald der Zertifikatsinhaber die Anforderung genehmigt oder ablehnt.",
    privacyNotice:
      " Mit Einreichen der Anfrage auf Dokumenteneinsicht willige ich ein, dass die Organisation, die dieses Zertifikat besitzt, meine E-Mail-Adresse erhält, um mich für eventuelle Rückfragen kontaktieren zu können!",
  },
  zerezIDForPowerRange: "ZEREZ ID für Leistungsbereich",
  historization: {
    open: "offen",
  },
};
