import { GetCertificatesToValidateQuery } from "graphqlBase/issue/__generated__/getCertificatesToValidate";
import { PaginatedResultType } from "graphqlBase/types";
import { getTranslatedIssueTypeString } from "lib/helper/getTranslatedRowDataStrings";
import { translations } from "translations";
import useGetTenantNameById from "utils/useGetTenantNameById";
export type Issue = PaginatedResultType<GetCertificatesToValidateQuery["issues"]>;

export const useSortDataByIssueTypeName = (data: Issue[], sorting: string, fieldName: string) => {
  const { tenantNameById } = useGetTenantNameById({ withDeniedTenants: true });
  if (fieldName === "manufacturer") {
    return data
      .sort((a, b) => {
        const valueA = tenantNameById[a.certificate.certificateHolderId]?.toLowerCase() ?? "";
        const valueB = tenantNameById[b.certificate.certificateHolderId]?.toLowerCase() ?? "";

        return sorting === translations.enums.sort.ASC ? valueA.localeCompare(valueB) : valueB.localeCompare(valueA);
      })
      .slice();
  }
  if (fieldName === "issueTypeName") {
    return data
      .sort((a, b) => {
        const valueA = getTranslatedIssueTypeString(a.issueTypeId).toLowerCase();
        const valueB = getTranslatedIssueTypeString(b.issueTypeId).toLowerCase();

        return sorting === translations.enums.sort.ASC ? valueA.localeCompare(valueB) : valueB.localeCompare(valueA);
      })
      .slice();
  }

  if (fieldName === "createdAt") return data;
};
