import { FormControl, Switch, Typography } from "@mui/material";
import { useUnitSubScribedByUserQuery } from "graphqlBase/unitBridgeUsers/__generated__/unitSubScribedByUser";
import React from "react";
import { useRecoilState } from "recoil";
import { queryFilterSelectorFamily } from "../../State/manageFiltersInUnitsOverview";
import { StyledToggleBox } from "../styles";

interface SubscribedFilterProps {
  userId: string;
  title: string;
}

const SubscribedFilter: React.FC<SubscribedFilterProps> = ({ userId, title }) => {
  const filterType = "subscribed";
  const { data } = useUnitSubScribedByUserQuery({
    variables: {
      userId,
    },
  });

  const unitsSubSubscribedByUser = data?.unitBridgeUsers?.items?.map((item) => item.unitId) ?? [];
  const [filters, setFilters] = useRecoilState(queryFilterSelectorFamily(filterType));
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFilters(event.target.checked ? unitsSubSubscribedByUser : []);
  };

  return (
    <>
      <FormControl sx={{ m: 1 }} size="small" aria-label={filterType}>
        <StyledToggleBox>
          <Typography sx={{ paddingLeft: "10px" }}>{title}</Typography>
          <Switch
            aria-label={filterType}
            checked={!!filters.length}
            onChange={handleChange}
            inputProps={{ "aria-label": "controlled" }}
          />
        </StyledToggleBox>
      </FormControl>
    </>
  );
};

export default SubscribedFilter;
