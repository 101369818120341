import { useMemo } from "react";
import { useRecoilValue } from "recoil";
import { unitsOverviewFilterAtom } from "../State/manageFiltersInUnitsOverview";

export default function useCheckIfFiltersAreApplied() {
  const filters = useRecoilValue(unitsOverviewFilterAtom);
  const filtersIsTrue = useMemo(
    () =>
      Object.entries(filters).reduce((acc, [key, filterValue]) => {
        if (filterValue === null) return acc;
        switch (key) {
          case "documentType":
          case "primaryEnergySource":
          case "categoryClassEquipment":
          case "certificateValidityStatus":
          case "certificateHolderId":
          case "certificateAuthorityId":
          case "tenantId":
          case "certificateNorms":
          case "subscribed":
            if (filterValue.length > 0) return true;
            break;
          case "hasZerezId":
            if (filterValue === true) return true;
            break;
          case "isVerified":
            if (filterValue === true) return true;
            break;
          case "createdAt":
          case "modifiedAt":
            if (filterValue !== "") return true;
            break;
          case "userIsSubscribed":
            if (filterValue) return true;
            break;
          case "ratedCurrent":
            if (filterValue[0] !== 0 || filterValue[1] <= 140) return true;
            break;
          case "ratedVoltage":
            if (filterValue[0] !== 0 || filterValue[1] <= 1001) return true;
            break;
          case "maxActivePower": {
            if (filterValue[0] !== 0 || filterValue[1] < 5000) return true;
            break;
          }
        }

        return acc;
      }, false),
    [filters]
  );
  return { filtersIsTrue };
}
