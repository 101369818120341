export default {
  category: {
    INVERTER: "Umrichter",
    STORAGE_INVERTER: "Umrichter des Speichers",
    ASYNCHRONOUS_GENERATOR: "Asynchrongenerator",
    SYNCHRONOUS_GENERATOR: "Synchrongenerator",
    FUEL_CELL: "Brennstoffzelle",
    UNDERVOLTAGE_PROTECTION: "NA-Schutz",
    PAV_E_MONITORING: "PAV,E-Überwachung",
    VOLTAGE_REGULATOR: "Spannungsregler",
    PROTECTIVE_DEVICE_INTERMEDIATE: "Schutzgerät (Zwischengelagert)",
    EZA_CONTROLLER: "EZA-Regler",
    OTHER: "Andere",
  },
  primaryEnergySource: {
    // BIOGAS: "Biogas",
    NOT_APPLICABLE: "Unzutreffend",
    OTHER: "Sonstiges",
    OTHER_FUELS: "Gasförmige und flüssige Brennstoffe",
    PV: "PV",
    PV_WITH_STORAGE: "PV / Speicher",
    STORAGE: "Speicher",
    WATER: "Wasser",
    WIND: "Wind",
  },
  netFeedIn: {
    SINGLE_PHASE: "Einphasig",
    TWO_PHASE: "Zweiphasig",
    THREE_PHASE: "Dreiphasig",
    UNKNOWN: "Unbekannt",
  },
  sort: {
    ASC: "Aufsteigend",
    DESC: "Absteigend",
    Default: "Standard",
  },
  tenantRole: {
    MANUFACTURER: "Hersteller",
    CERTIFICATION_AUTHORITY: "Zertifizierungsstelle",
    NETWORK_OPERATOR: "Netzbetreiber",
    PLATFORM_OPERATOR: "Plattformbetreiber",
    PLANNER: "Planer",
    TENANT_ADMIN: "Administrator",
  },
  accessRequestStatus: {
    REQUESTED: "Angefragt",
    APPROVED: "Genehmigt",
    REJECTED: "Abgelehnt",
  },
  approvalStatus: {
    PENDING: "Ausstehend",
    APPROVED: "Genehmigt",
    DENIED: "Abgelehnt",
    PENDING_INVITATION: "Einladung ausstehend",
    WITHOUT_EA_APPROVAL: "Ohne EA-Genehmigung",
  },
  certificateValidityStatuses: {
    "Expired / Invalid": "Abgelaufen",
    "Withdrawn (replaced by)": "Zurückgezogen (ersetzt durch)",
    "Withdrawn (not replaced)": "Zurückgezogen (nicht ersetzt)",
    "Valid / Ongoing": "Gültig",
    Void: "Ungültig",
  },
  issueStatusName: {
    Open: "Offen",
    Closed: "Geschlossen",
    Resolved: "Gelöst",
  },
  issueSubTypeNameForFilter: {
    Attachments: "Anhang",
    "Cover Sheet": "Deckblatt",
    "Digital Attachment": "Digitaler Anhang",
    "Unit Parameters": "Einheitenparameter",
    Validity: "Gültigkeit",
  },
  issueSubTypeName: {
    "7b60458e-c025-5dbe-8825-0ef45de4e5d5": "Anhang",
    "57b08c29-018e-5909-85bf-f4d11a7e8aa2": "Deckblatt",
    "48442616-8f96-5d19-beb6-9700fd4be435": "Digitaler Anhang",
    "1a614968-01aa-585c-bcfc-0aad8ba2ee63": "Einheitenparameter",
    "6ca69681-8ff3-55e4-afc7-b8f376f9ee22": "Gültigkeit",
  },
};
