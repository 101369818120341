export default {
  assignRolesToUser: {
    label: "Roles To User Assignment",
  },
  button: {
    create: {
      role: "New role",
      apiKey: "New  API Key",
    },
  },
  form: {
    //   edit: {
    //     listLabel: {
    //       assigned: "Assigned",
    //       unassigned: "Unassigned",
    //     },
    //     tabLabel: {
    //       assignScope: "Assign Permissions",
    //       assignUser: "Assign Users",
    //       editRole: "Change Role",
    //     },
    //   },
    label: {
      deleteUserRole: "Delete user role",
      revokeApiKey: "Revoke API Key",
      editRole: "Edit role",
      role: "Edit Role",
      allUsers: "All Users",
      keyLabel: "Key Label",
      tenantDeletionFailed: "Tenant deletion failed",
      tenantDeleteSuccess: "Tenant successfully deleted",
      checkTenantRole: "Check Tenant Role",
      deleteTenant: "Delete Tenant",
      deleteNotPossible: "Delete not possible",
      deleteUser: "Delete User",
      // user: "Edit User",
    },
    error: {
      disabledButtonForCreate: "Missing required Permissions for this action",
      maxApiKey: "A maximum of two API Keys can be managed simultaneously.",
      deleteUserRole: "Cannot delete user role, tenant should have at least one tenant admin",
    },
    message: {
      continueDeleteMessage: "Are you sure you want to continue?",
      userRoleDeleteMessagePt1: "This user role is currently assignet to",
      userRoleDeleteMessagePt2: "They will no longer have access to certain app areas.",
      userErrorMessage: "the display name should not be empty.",
      userNameErrorMessage: "The user name should not be empty.",
      userLastNameErrorMessage: "The user last name should not be empty.",
      phoneNumerErrorMessage: "The entered value must be a phone number.",
      streetAndNumberErrorMessage:
        "Please enter the street name followed by a comma and the house number (e.g. Hauptstraße, 123).",
      postalCodeErrorMessage: "Please enter a valid postal code consisting of only one or more digits.",
      requiredInformation: "This information is required.",
      tenantNameExists: "The name of the Tenant already exists, please enter another name.",
      userBlockMessage: "Are you sure you want to block this user?",
      userUnblockMessage: "Are you sure you want to unblock this user?",
      apiDescription: "To learn more about how API keys work, please refer to the ",
      externalDocumentation: "external documentation",
      revokeApiKey: "Are you sure you want to revoke this secret key?",
      newApiKeyMessagePart1: " Here is your API key for the ",
      newApiKeyMessagePart2: " test.",
      newApiKeyMessagePart3:
        " Please note that you won't be able to view this key again after closing this window, so make sure to record it securely.",
      newApiKeyWarningMessage:
        "It appears that an error occurred while creating the API key. Please try again and contact the platform operator if the problem persists.",
      deleteTenantNotPossible: "Tenant must not be deleted since there are still remaining users assigned.",
      deleteTenantSuccess: "The old Tenant was successfully deleted.",
      tenantCanBeDeleted: ({ tenantName }: { tenantName: string | undefined }) =>
        `There are no longer any users associated with the orgnization${
          tenantName && ` "${tenantName}"`
        }. Do you want to remove this organization from the database?`,
      tenantCanNotBeDeleted: ({ tenantName }: { tenantName: string | undefined }) =>
        `The organization ${
          tenantName && `"${tenantName}" `
        }cannot currently be deleted because other users are currently assigned to this organization. Beforehand, assign all remaining users to a new organization to be able to remove ${
          tenantName ? `"${tenantName}"` : "it"
        } from the database.`,
      checkTenantRole: ({ tenantName }: { tenantName: string | undefined }) =>
        `The user's current role does not match the role of the newly assigned organization${
          tenantName && ` "${tenantName}"`
        }. Do you still want to continue?`,
      retryInviteUser: ({ displayName }: { displayName: string }) =>
        `Do you really want to invite ${displayName} again?`,
      deleteUsers: "Do you really want to delete the selected user(s)?",
      deleteTenant: "Do you really want to delete the selected tenant(s)?",
      deleteTenantsNotPossible:
        "Tenant must not be deleted since there are still remaining users or certificates assigned.",
    },
  },
  inviteUsers: {
    label: "Invite Users",
    formFields: {
      firstName: "First Name",
      lastName: "Last Name",
      emailAdress: "Email Address",
    },
  },
  label: "User Management",
  table: {
    column: {
      label: "Label",
      secret: "Secret",
      description: "Description",
      email: "Email",
      firstName: "First Name",
      lastName: "Last Name",
      name: "Name",
      clientId: "Client ID",
      explanationWhyRevokeButtonIsDisabled:
        "For technical reasons, you have to keep at least one API key. If you want to delete this key, please create a new one first.",
      pendingRequestsAction: "Action",
      role: "Roles of the User",
      roleDescription: "Role description",
      tenant: "Tenant / Company",
      userRole: "User role name",
      value: "Value",
    },
  },
  tabs: {
    pendingUserRequests: "Pending User Requests",
    userAndRolesAssignment: "User and Roles Assignment",
    apiKeys: "API Keys",
  },
  userAuthorization: "User Authorization",
  tenantOverview: "Tenant Overview",
  userRoleManager: {
    assignedTo: "Assigned to",
    createdUserRole: "Your user role has been succesfully created.",
    discardChanges: "Discard changes",
    discardMessage:
      "You've commited changes that are not saved. Are you sure you want to quit this page and leave those changes unsaved? ",
    inviteUser: "Invite User",
    label: "User Role Manager",
    noUsersAssignedMessage: "There are no users assigned to this role yet.",
    permissions: "Permissions",
    roleName: "Role Name",
    selectRole: "Select Role",
    useRoleCreate: "User role created",
  },
};
