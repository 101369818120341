import { Maybe } from "graphqlBase/types";
import { uploadCertificateUnitListAtom } from "pages/UploadCertificate/state/uploadCertificateUnitState";
import { getCertificateCreateFormAtom } from "raft/CertificateForm";
import { getRecoilStore } from "recoil-toolkit";
import { UploadCertificateUnit } from "./types";
import { isNormEqualOrGreater10 } from "./validationRules/utils";

export interface GeManufacturerAndUnitsInfoResult {
  manufacturerId: string | undefined;
  allUnitNames: (string | undefined)[];
  allUnitIds: string[];
}

const getUnitIdsNames = async (allUnits: UploadCertificateUnit[]) => {
  return allUnits.reduce<{ allUnitNames: (string | undefined)[]; allUnitIds: string[] }>(
    (acc, unit) => {
      return {
        allUnitNames: acc.allUnitNames.concat(unit.modelName),
        allUnitIds: unit.id ? acc.allUnitIds.concat(unit.id) : acc.allUnitIds,
      };
    },
    { allUnitNames: [], allUnitIds: [] }
  );
};

export const getManufacturerAndUnitsInfo = async (): Promise<GeManufacturerAndUnitsInfoResult> => {
  const certificateCreateForm = getCertificateCreateFormAtom({});
  const store = await getRecoilStore();

  const form = await store.getPromise(certificateCreateForm);
  const allUnits = await store.getPromise(uploadCertificateUnitListAtom);
  const { allUnitNames, allUnitIds } = await getUnitIdsNames(allUnits);
  return { manufacturerId: form?.certificateHolderId, allUnitNames, allUnitIds };
};

export const setTG8Rev25Conformity = (data: { __normList?: string; withTG8Rev25Conformity?: Maybe<boolean> }) => {
  const isNorm10Up = isNormEqualOrGreater10(data);
  return (data.withTG8Rev25Conformity ?? false) && isNorm10Up;
};
